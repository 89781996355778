.BDPost {
    margin-block-end: 1rem;
    padding: 0.5rem 0;
    background: white;
}

.BDPost section.header,
.BDPost section.footer,
.BDPost section.comments {
    padding: 0 0.5rem;
}
.BDPost section.header .Liker {
    float: right;
}
.BDPost section.header h1 {
    font-size: 1.5rem;
    word-break: break-word;
    margin: 0;
}
.BDPost section.header p.details {
    margin: 0;
}
.BDPost section.bd {
    border: 1px solid #D1D1D1;
    margin: .5em;
    overflow-x: auto;
}

@media only screen and (min-width: 838px) {
    .postsList {
        display: flex;
        flex-direction: column;
        align-items: center;

        .BDPost {
            width: min-content;
        }
    }
}
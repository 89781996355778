.Scene {
  position: relative;
  display: block;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 822px;
  height: 304px;

  #vsep1 {left: 270px;}
  #vsep2 {left: 540px;}
  #bird1 {left: 20px;}
  #bird2 {left: 145px;}
  #bird3 {left: 290px;}
  #bird4 {left: 420px;}
  #bird5 {left: 560px;}
  #bird6 {left: 690px;}
  #bubble1 {left: 13px;}
  #bubble2 {left: 283px;}
  #bubble3 {left: 554px;}

  &.square {
    width: 280px;
    #vsep1, #vsep2, #bird3, #bird4, #bird5, #bird6, #bubble2, #bubble3 { display: none; }
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #ccc;

    &.bluesky {background: url('../../assets/back_bluesky.png') no-repeat 0 0;}
    &.noel_day {background: url('../../assets/back_noel_day.png') no-repeat 0 0;}
    &.noel_night {background: url('../../assets/back_noel_night.png') no-repeat 0 0;}
    &.halloween_1 {background: url('../../assets/back_halloween_1.png') no-repeat 0 0;}
    &.halloween_2 {background: url('../../assets/back_halloween_2.png') no-repeat 0 0;}
    &.halloween_3 {background: url('../../assets/back_halloween_3.png') no-repeat 0 0;}
    &.summer {background: url('../../assets/back_summer.png') no-repeat 0 0;}

    &.sq_bluesky {background: url('../../assets/back_sq_bluesky.png') no-repeat 0 0;}
  }

  .background_edit_btn {
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 34px;
    height: 34px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: 1px solid transparent;
    background: url('../../assets/icons/edit_background.png') no-repeat 0 0;

    &:hover,
    &.selected {
      border-color: #1A2CED;
    }
  }

  .vsep {
    position: absolute;
    width: 10px;
    top: 0;
    bottom: 0;
    left: -10px;
    cursor: pointer;

    &.white {background-color: #fff;}
    &.transparent {background-color: transparent;}
    &:hover {background-color: #FF0000;}
  }

  .holder {
    border: 1px solid transparent;
    background-color: none;
    cursor: pointer;

    .clear_btn {
      position: absolute;
      bottom: -16px;
      width: 34px;
      height: 34px;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background: url('../../assets/icons/clear_holder.png') no-repeat 0 0;
    }

    &:hover{border-color: #787878;}

    &.bird-holder {
      position: absolute;
      width: 110px;
      height: 120px;
      bottom: 40px;
      left: -80px;
      background-repeat: no-repeat;
      background-position: 50% -28px;
      background-image: url('../../assets/misc/bird_unknown.png');

      &.selected {border-color: #1A2CED;}
    }

    &.bubble-holder {
      position: absolute;
      width: 250px;
      height: 130px;
      top: 20px;
      left: -150px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url('../../assets/misc/bubble_unknown.png');

      &.selected {border-color: #1A2CED;}

      .text-wrapper {
        position: absolute;
        background-image: none;

        textarea {
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 10px;
          background-color: transparent;
          font-family: 'gilles_comic_fontregular', Comic Sans MS, Verdana, sans-serif;
          font-size: 17px;
          line-height: 22px;
          text-align: left;
          resize: none;
          white-space: pre-wrap;
          overflow: hidden;
          display: none;
        }

        &.little {
          top: 32px;
          left: 25px;
          bottom: 50px;
          right: 25px;

          textarea.ta1 {display: inline-block;}
        }
        &.thought {
          top: 32px;
          left: 25px;
          bottom: 50px;
          right: 25px;

          textarea.ta1 {display: inline-block;}
        }
        &.big {
          top: 10px;
          left: 25px;
          bottom: 25px;
          right: 25px;

          textarea {
            border-radius: 0;

            &.ta1 {display: inline-block;}
          }
        }
        &.full {
          top: 10px;
          left: 25px;
          bottom: 15px;
          right: 30px;

          textarea.ta1 {display: inline-block;}
        }

        &.left-right {
          top: 8px;
          left: 10px;
          bottom: 25px;
          right: 3px;

          textarea {
            display: block;
            width: 210px;
            height: 45px;

            &.ta2 {
              margin-left: 30px;
              margin-top: 5px;
            }
          }
        }

        &.right-left {
          top: 8px;
          left: 10px;
          bottom: 25px;
          right: 3px;

          textarea {
            display: block;
            width: 210px;
            height: 45px;

            &.ta1 {
              margin-left: 30px;
              margin-top: 0px;
            }

            &.ta2 {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
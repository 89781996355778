.Exporter {
  position: fixed;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0,0,0,.8);

  .modal {
    display: flex;
    max-width: fit-content;
    flex-direction: column;
    gap: 0.5em;

    background-color: white;
    margin: 1em auto 15%;
    padding: 1em;

    h2 {
      margin:0 0 0.5em;
      font-size: 1.2rem;
    }

    .header {
      position: relative;

      .closeButton {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .bdPreview {
      border: 1px solid #D1D1D1;
      overflow: auto;
    }

  }
}
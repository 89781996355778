.Scene {
  position: relative;
  display: block;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 822px;
  height: 304px;
}
.Scene #vsep1 {
  left: 270px;
}
.Scene #vsep2 {
  left: 540px;
}
.Scene #bird1 {
  left: 20px;
}
.Scene #bird2 {
  left: 145px;
}
.Scene #bird3 {
  left: 290px;
}
.Scene #bird4 {
  left: 420px;
}
.Scene #bird5 {
  left: 560px;
}
.Scene #bird6 {
  left: 690px;
}
.Scene #bubble1 {
  left: 13px;
}
.Scene #bubble2 {
  left: 283px;
}
.Scene #bubble3 {
  left: 554px;
}
.Scene.square {
  width: 280px;
}
.Scene.square #vsep1,
.Scene.square #vsep2,
.Scene.square #bird3,
.Scene.square #bird4,
.Scene.square #bird5,
.Scene.square #bird6,
.Scene.square #bubble2,
.Scene.square #bubble3 {
  display: none;
}
.Scene .bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #ccc;
}
.Scene .bg.bluesky {
  background: url('../../assets/back_bluesky.png') no-repeat 0 0;
}
.Scene .bg.noel_day {
  background: url('../../assets/back_noel_day.png') no-repeat 0 0;
}
.Scene .bg.noel_night {
  background: url('../../assets/back_noel_night.png') no-repeat 0 0;
}
.Scene .bg.halloween_1 {
  background: url('../../assets/back_halloween_1.png') no-repeat 0 0;
}
.Scene .bg.halloween_2 {
  background: url('../../assets/back_halloween_2.png') no-repeat 0 0;
}
.Scene .bg.halloween_3 {
  background: url('../../assets/back_halloween_3.png') no-repeat 0 0;
}
.Scene .bg.summer {
  background: url('../../assets/back_summer.png') no-repeat 0 0;
}
.Scene .bg.sq_bluesky {
  background: url('../../assets/back_sq_bluesky.png') no-repeat 0 0;
}
.Scene .background_edit_btn {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 34px;
  height: 34px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 1px solid transparent;
  background: url('../../assets/icons/edit_background.png') no-repeat 0 0;
}
.Scene .background_edit_btn:hover,
.Scene .background_edit_btn.selected {
  border-color: #1A2CED;
}
.Scene .vsep {
  position: absolute;
  width: 10px;
  top: 0;
  bottom: 0;
  left: -10px;
  cursor: pointer;
}
.Scene .vsep.white {
  background-color: #fff;
}
.Scene .vsep.transparent {
  background-color: transparent;
}
.Scene .vsep:hover {
  background-color: #FF0000;
}
.Scene .holder {
  border: 1px solid transparent;
  background-color: none;
  cursor: pointer;
}
.Scene .holder .clear_btn {
  position: absolute;
  bottom: -16px;
  width: 34px;
  height: 34px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: url('../../assets/icons/clear_holder.png') no-repeat 0 0;
}
.Scene .holder:hover {
  border-color: #787878;
}
.Scene .holder.bird-holder {
  position: absolute;
  width: 110px;
  height: 120px;
  bottom: 40px;
  left: -80px;
  background-repeat: no-repeat;
  background-position: 50% -28px;
  background-image: url('../../assets/misc/bird_unknown.png');
}
.Scene .holder.bird-holder.selected {
  border-color: #1A2CED;
}
.Scene .holder.bubble-holder {
  position: absolute;
  width: 250px;
  height: 130px;
  top: 20px;
  left: -150px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url('../../assets/misc/bubble_unknown.png');
}
.Scene .holder.bubble-holder.selected {
  border-color: #1A2CED;
}
.Scene .holder.bubble-holder .text-wrapper {
  position: absolute;
  background-image: none;
}
.Scene .holder.bubble-holder .text-wrapper textarea {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  font-family: 'gilles_comic_fontregular', Comic Sans MS, Verdana, sans-serif;
  font-size: 17px;
  line-height: 22px;
  text-align: left;
  resize: none;
  white-space: pre-wrap;
  overflow: hidden;
  display: none;
}
.Scene .holder.bubble-holder .text-wrapper.little {
  top: 32px;
  left: 25px;
  bottom: 50px;
  right: 25px;
}
.Scene .holder.bubble-holder .text-wrapper.little textarea.ta1 {
  display: inline-block;
}
.Scene .holder.bubble-holder .text-wrapper.thought {
  top: 32px;
  left: 25px;
  bottom: 50px;
  right: 25px;
}
.Scene .holder.bubble-holder .text-wrapper.thought textarea.ta1 {
  display: inline-block;
}
.Scene .holder.bubble-holder .text-wrapper.big {
  top: 10px;
  left: 25px;
  bottom: 25px;
  right: 25px;
}
.Scene .holder.bubble-holder .text-wrapper.big textarea {
  border-radius: 0;
}
.Scene .holder.bubble-holder .text-wrapper.big textarea.ta1 {
  display: inline-block;
}
.Scene .holder.bubble-holder .text-wrapper.full {
  top: 10px;
  left: 25px;
  bottom: 15px;
  right: 30px;
}
.Scene .holder.bubble-holder .text-wrapper.full textarea.ta1 {
  display: inline-block;
}
.Scene .holder.bubble-holder .text-wrapper.left-right {
  top: 8px;
  left: 10px;
  bottom: 25px;
  right: 3px;
}
.Scene .holder.bubble-holder .text-wrapper.left-right textarea {
  display: block;
  width: 210px;
  height: 45px;
}
.Scene .holder.bubble-holder .text-wrapper.left-right textarea.ta2 {
  margin-left: 30px;
  margin-top: 5px;
}
.Scene .holder.bubble-holder .text-wrapper.right-left {
  top: 8px;
  left: 10px;
  bottom: 25px;
  right: 3px;
}
.Scene .holder.bubble-holder .text-wrapper.right-left textarea {
  display: block;
  width: 210px;
  height: 45px;
}
.Scene .holder.bubble-holder .text-wrapper.right-left textarea.ta1 {
  margin-left: 30px;
  margin-top: 0px;
}
.Scene .holder.bubble-holder .text-wrapper.right-left textarea.ta2 {
  margin-top: 5px;
}

.PublishForm {

  form {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    button[type="submit"] {
      justify-items: center;
    }
  }
}
.Galleries {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;

  nav {
      padding-bottom: 1rem;

      div {
        border-radius: 2px;
        border: 1px solid #ccc;
        background-color: #fff;

        &.icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: #fff;
          cursor: pointer;

          &.birds-icon {background: url('../../assets/icons/new_bird_icon.png') no-repeat 50% 50%; }
          &.news-icon {background: url('../../assets/icons/newspaper_icon.png') no-repeat 50% 50%; }
          &.lady-icon {background: url('../../assets/icons/lady_icon.png') no-repeat 50% 50%; }
          &.baby-icon {background: url('../../assets/icons/baby_icon.png') no-repeat 50% 50%; }
          &.old-icon {background: url('../../assets/icons/old_birds_icon.png') no-repeat 50% 50%; }
          &.politic-icon {background: url('../../assets/icons/politic_icon.png') no-repeat 50% 50%; }
          &.corbac-icon {background: url('../../assets/icons/corbac_icon.png') no-repeat 50% 50%; }
          &.foot-icon {background: url('../../assets/icons/foot_icon.png') no-repeat 50% 50%; }
          &.halloween-icon {background: url('../../assets/icons/halloween_citrouille.png') no-repeat 50% 50%; }
          &.noel-icon {background: url('../../assets/icons/noel_papa.png') no-repeat 50% 50%; }
          &.police-icon {background: url('../../assets/icons/police_icon.png') no-repeat 50% 50%; }
          &.hopital-icon {background: url('../../assets/icons/hopital_icon.png') no-repeat 50% 50%; }
          &.summer-icon {background: url('../../assets/icons/summer_birds_icon.png') no-repeat 50% 50%; }
          &.giletsjaunes-icon {background: url('../../assets/icons/giletsjaunes_icon.png') no-repeat 50% 50%; }
          &.masques-icon {background: url('../../assets/icons/masques_icon.png') no-repeat 50% 50%; }
          &.bubble-icon {background: url('../../assets/icons/bubble_icon2.png') no-repeat 50% 50%; }
        }

        &:hover {
          border-bottom: 2px solid #3A88E0;
        }
        &.selected {
          border-bottom: 2px solid #3A88E0;
        }
      }
  }

  .gallery {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 824px;

    &.background-gallery {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h2 {
        font-size: 1rem;
        margin: 0;
      }
    }

    .thumb {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      border: 1px solid #ccc;
      margin-right: 5px;
      margin-bottom: 5px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50% 80%;
      background-size: 150%;
      cursor: pointer;

      &.selected {
        border-color: #1A2CED;
      }

      &:hover {
        background-color: #D2D5FA;
      }

      &.bubbles {
        background-color: #2c80dd;
        background-position: 50% 50%;
        background-size: 90%;

        &:hover {
          background-color: #2975cc;
        }
      }
    }
}
}
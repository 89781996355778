.UserProfile {
  position: absolute;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 2em;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: white;
}
.UserProfile h1 {
  margin-top: 0;
}
.UserProfile .closeButton {
  position: absolute;
  right: 1em;
  top: 1em;
}
.UserProfile ol.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.UserProfile ol.menu li {
  margin: 0.8rem 0;
}
.UserProfile ol.menu li button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.UserProfile ol.menu li button:hover {
  cursor: pointer;
  color: #145DCA;
}

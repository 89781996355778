.Comments {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    .comment_author {
      font-weight: bold;
    }
    .comment_date {
      margin-left: 0.5em;
      font-size: .8rem;
      color: #696968;
    }
    .comment_content p {
      margin: 0 0 0.5em;
    }
  }
}
.Header {
  align-self: stretch;
  margin: 0 0 1rem;
  background: linear-gradient(#3a88e0, #d9eafe);
}
.Header .TopBar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.Header .TopBar img.logo {
  display: block;
  margin: 0 auto;
  height: 3rem;
}
.TopNav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  border-bottom: #000000 4px solid;
  margin: 0;
  padding: 0;
}
.TopNav ul li a {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  text-decoration: none;
}
.TopNav ul li a.active {
  background-color: #ffffff;
}

.Header {
    align-self: stretch;
    margin: 0 0 1rem;
    background: linear-gradient(#3a88e0, #d9eafe);

    .TopBar {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3em;

        img.logo {
            display: block;
            margin: 0 auto;
            height: 3rem;
        }

        .ConnectionButton {

        }
    }
}

.TopNav {
    ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        border-bottom: #000000 4px solid;
        margin: 0;
        padding: 0;

        li a{
            display: inline-block;
            font-size: 1.2rem;
            padding: 0.5rem 2rem;
            text-decoration: none;

            &.active{
                background-color: #ffffff;
            }
        }
    }
}

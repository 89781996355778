button.Liker {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
button.Liker .count {
  font-weight: bolder;
}
button.Liker .heart {
  font-size: 1.8rem;
  color: #868686;
}
button.Liker:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
button.Liker.liked .heart {
  color: #ed1a33;
}
button.Liker.busy {
  opacity: 0.5;
}

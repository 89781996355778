.App {
  background-color: #d9eafe;
  height: 100vh;
  overflow: auto;
}
.App a.button,
.App button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  background-color: white;
  border: 1px solid #2e7cd4;
  border-radius: 0.2em;
  padding: 0.2em 0.4em;
  text-decoration: none;
  color: #333333;
  font-size: 1em;
}
.App a.button:hover,
.App button:hover {
  background-color: #f1f1f1;
  cursor: pointer;
  color: #2e7cd4;
}
.App a.button:disabled,
.App button:disabled,
.App a.button:disabled:hover,
.App button:disabled:hover {
  background-color: white;
  border-color: #b7b7b7;
  color: #b7b7b7;
  cursor: not-allowed;
}
.App a.button span.icon,
.App button span.icon {
  font-weight: bold;
  font-size: 1em;
}

.Pager {
    margin: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.Pager .PageLink {
    flex: 1 0 0;
    padding: 0.5rem;
    color: #145DCA;
    background-color: #ffffff;
    text-align: center;
    text-decoration: none;
    border: #145DCA 1px solid;
    border-radius: 1rem;
}
.ConnectionButton {
    &.connected {
      > button {

        img.avatar {
          width: 1.5em;
          height: 1.5em;
        }

        span.displayName {
          font-weight: bold;
        }
    }
  }
}
@assets_url : '../../assets/';
.birds, #bd_editor #scene .holder.bird-holder {
  &.unknown {background-image: url('@{assets_url}misc/unknown.png'); }
  &.normal {background-image: url('@{assets_url}birds/normal.png');
    &.left {background-image: url('@{assets_url}birds/normal_left.png'); }
    &.right {background-image: url('@{assets_url}birds/normal_right.png'); }
    &.down {background-image: url('@{assets_url}birds/normal_down.png');
      &.left {background-image: url('@{assets_url}birds/normal_down_left.png'); }
      &.right {background-image: url('@{assets_url}birds/normal_down_right.png'); }
    }
    &.up {
      &.left {background-image: url('@{assets_url}birds/normal_up_left.png');}
      &.right {background-image: url('@{assets_url}birds/normal_up_right.png');}
    }
  }
  &.speaking {background-image: url('@{assets_url}birds/speaking.png');
    &.left {background-image: url('@{assets_url}birds/speaking_left.png'); }
    &.right {background-image: url('@{assets_url}birds/speaking_right.png'); }
  }
  &.mdr {
    &.left {background-image: url('@{assets_url}birds/mdr_left.png'); }
    &.right {background-image: url('@{assets_url}birds/mdr_right.png'); }
  }
  &.afraid {background-image: url('@{assets_url}birds/afraid.png');
    &.left {background-image: url('@{assets_url}birds/afraid_left.png'); }
    &.right {background-image: url('@{assets_url}birds/afraid_right.png'); }
    &.speaking {background-image: url('@{assets_url}birds/afraid_speaking.png');
      &.left {background-image: url('@{assets_url}birds/afraid_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}birds/afraid_speaking_right.png'); }
    }
  }
  &.amused {
    &.left {background-image: url('@{assets_url}birds/amused_left.png'); }
    &.right {background-image: url('@{assets_url}birds/amused_right.png'); }
  }
  &.bending {
    &.left {background-image: url('@{assets_url}birds/bending_left.png'); }
    &.right {background-image: url('@{assets_url}birds/bending_right.png'); }
  }
  &.blind {
    &.left {background-image: url('@{assets_url}birds/blind_left.png'); }
    &.right {background-image: url('@{assets_url}birds/blind_right.png'); }
  }
  &.sing {
    &.left {background-image: url('@{assets_url}birds/sing_left.png'); }
    &.right {background-image: url('@{assets_url}birds/sing_right.png'); }
  }
  &.crazy {background-image: url('@{assets_url}birds/crazy.png'); }
  &.excusing {
    &.left {background-image: url('@{assets_url}birds/excusing_left.png'); }
    &.right {background-image: url('@{assets_url}birds/excusing_right.png'); }
  }
  &.flap {
    &.left {background-image: url('@{assets_url}birds/flap_left.png'); }
    &.right {background-image: url('@{assets_url}birds/flap_right.png'); }
  }
  &.go {
    &.left {background-image: url('@{assets_url}birds/go_left.png'); }
    &.right {background-image: url('@{assets_url}birds/go_right.png'); }
  }
  &.haughty {background-image: url('@{assets_url}birds/haughty.png');
    &.speaking {background-image: url('@{assets_url}birds/haughty_speaking.png'); }
  }
  &.laughing {
    &.left {background-image: url('@{assets_url}birds/laughing_left.png'); }
    &.right {background-image: url('@{assets_url}birds/laughing_right.png'); }
  }
  &.listening {
    &.left {background-image: url('@{assets_url}birds/listening_left.png'); }
    &.right {background-image: url('@{assets_url}birds/listening_right.png'); }
  }
  &.love { background-image: url('@{assets_url}birds/love.png');
    &.left {background-image: url('@{assets_url}birds/love_left.png'); }
    &.right {background-image: url('@{assets_url}birds/love_right.png'); }
  }
  &.pissed {background-image: url('@{assets_url}birds/pissed.png');
    &.speaking {background-image: url('@{assets_url}birds/pissed_speaking.png'); }
  }
  &.whispering {
    &.left {background-image: url('@{assets_url}birds/whispering_left.png'); }
    &.right {background-image: url('@{assets_url}birds/whispering_right.png'); }
  }
}

.bubbles, #bd_editor #scene .holder.bubble-holder.bubbles {
  &.little {
    &.left {background-image: url('@{assets_url}bubbles/little_left.png'); }
    &.right {background-image: url('@{assets_url}bubbles/little_right.png'); }
  }
  &.big {
    &.left {background-image: url('@{assets_url}bubbles/big_left.png'); }
    &.right {background-image: url('@{assets_url}bubbles/big_right.png'); }
  }
  &.thought {
    &.left {background-image: url('@{assets_url}bubbles/thought_left.png'); }
    &.right {background-image: url('@{assets_url}bubbles/thought_right.png'); }
  }
  &.left-right {background-image: url('@{assets_url}bubbles/left-right.png'); }
  &.right-left {background-image: url('@{assets_url}bubbles/right-left.png'); }
  &.full {background-image: url('@{assets_url}bubbles/full.png'); }
  &.heart {background-image: url('@{assets_url}bubbles/heart.png'); }
  &.hearts {background-image: url('@{assets_url}bubbles/hearts.png'); }

  &.christmas.santa.flying {
    background-image: url('@{assets_url}bubbles/christmas_santa_flying.png');
  }
}

.news, #bd_editor #scene .holder.bird-holder.news{
  &.afraid {background-image: url('@{assets_url}news/afraid.png');}
  &.behindnewspaper {background-image: url('@{assets_url}news/behindnewspaper.png');}
  &.normal {background-image: url('@{assets_url}news/normal.png');
    &.left {background-image: url('@{assets_url}news/normal_left.png'); }
    &.right {background-image: url('@{assets_url}news/normal_right.png'); }
  }
  &.pissed {background-image: url('@{assets_url}news/pissed.png');
    &.speaking {background-image: url('@{assets_url}news/pissed_speaking.png');}
  }
  &.speaking {
    &.left {background-image: url('@{assets_url}news/speaking_left.png'); }
    &.right {background-image: url('@{assets_url}news/speaking_right.png'); }
  }
  &.lady {
    &.afraid {background-image: url('@{assets_url}news/lady_afraid.png');}
    &.behindnewspaper {background-image: url('@{assets_url}news/lady_behindnewspaper.png');}
    &.normal {background-image: url('@{assets_url}news/lady_normal.png');
      &.left {background-image: url('@{assets_url}news/lady_normal_left.png'); }
      &.right {background-image: url('@{assets_url}news/lady_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}news/lady_speaking.png');
      &.left {background-image: url('@{assets_url}news/lady_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}news/lady_speaking_right.png'); }
    }
  }
}

.lady, #bd_editor #scene .holder.bird-holder.lady{
  &.normal {background-image: url('@{assets_url}lady/normal.png');
    &.left {background-image: url('@{assets_url}lady/normal_left.png'); }
    &.right {background-image: url('@{assets_url}lady/normal_right.png'); }
    &.up {
      &.left {background-image: url('@{assets_url}lady/normal_up_left.png');}
      &.right {background-image: url('@{assets_url}lady/normal_up_right.png');}
    }
  }
  &.bending {
    &.left {background-image: url('@{assets_url}lady/bending_left.png'); }
    &.right {background-image: url('@{assets_url}lady/bending_right.png'); }
  }
  &.go {
    &.left {background-image: url('@{assets_url}lady/go_left.png'); }
    &.right {background-image: url('@{assets_url}lady/go_right.png'); }
  }
  &.shocked {background-image: url('@{assets_url}lady/shocked.png');}
  &.speaking {background-image: url('@{assets_url}lady/speaking.png');
    &.left {background-image: url('@{assets_url}lady/speaking_left.png'); }
    &.right {background-image: url('@{assets_url}lady/speaking_right.png'); }
    &.smiling {
      &.left {background-image: url('@{assets_url}lady/speaking_smiling_left.png'); }
      &.right {background-image: url('@{assets_url}lady/speaking_smiling_right.png'); }
    }
  }
  &.mdr {
    &.left {background-image: url('@{assets_url}lady/mdr_left.png'); }
    &.right {background-image: url('@{assets_url}lady/mdr_right.png'); }
  }
  &.smile {
    &.left {background-image: url('@{assets_url}lady/smile_left.png'); }
    &.right {background-image: url('@{assets_url}lady/smile_right.png'); }
  }
  &.love {
    &.left {background-image: url('@{assets_url}lady/love_left.png'); }
    &.right {background-image: url('@{assets_url}lady/love_right.png'); }
  }
  &.pissed {background-image: url('@{assets_url}lady/pissed.png');
    &.speaking {background-image: url('@{assets_url}lady/pissed_speaking.png'); }
  }
  &.turning {
    &.left {background-image: url('@{assets_url}lady/turning_left.png'); }
    &.right {background-image: url('@{assets_url}lady/turning_right.png'); }
  }
  &.whispering {
    &.left {background-image: url('@{assets_url}lady/whispering_left.png'); }
    &.right {background-image: url('@{assets_url}lady/whispering_right.png'); }
  }
}

.baby, #bd_editor #scene .holder.bird-holder.baby{
  &.girl {
    &.normal {
      &.left {background-image: url('@{assets_url}baby/girl_normal_left.png'); }
      &.right {background-image: url('@{assets_url}baby/girl_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}baby/girl_speaking.png');
      &.left {background-image: url('@{assets_url}baby/girl_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}baby/girl_speaking_right.png'); }
    }
    &.ask {background-image: url('@{assets_url}baby/girl_ask.png'); }
    &.angry {background-image: url('@{assets_url}baby/girl_angry.png'); }
    &.crying {background-image: url('@{assets_url}baby/girl_crying.png'); }
    &.sulk {background-image: url('@{assets_url}baby/girl_sulk.png'); }
    &.amaze {background-image: url('@{assets_url}baby/girl_amaze.png'); }
  }
  &.boy {
    &.normal {
      &.left {background-image: url('@{assets_url}baby/boy_normal_left.png'); }
      &.right {background-image: url('@{assets_url}baby/boy_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}baby/boy_speaking.png');
      &.left {background-image: url('@{assets_url}baby/boy_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}baby/boy_speaking_right.png'); }
    }
    &.ask {background-image: url('@{assets_url}baby/boy_ask.png'); }
    &.angry {background-image: url('@{assets_url}baby/boy_angry.png'); }
    &.crying {background-image: url('@{assets_url}baby/boy_crying.png'); }
    &.sulk {background-image: url('@{assets_url}baby/boy_sulk.png'); }
    &.amaze {background-image: url('@{assets_url}baby/boy_amaze.png'); }
  }
}

.old, #bd_editor #scene .holder.bird-holder.old {
  &.oldbird {
    &.normal {
      background-image: url('@{assets_url}old/oldbird_normal.png');
      &.left {
        background-image: url('@{assets_url}old/oldbird_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}old/oldbird_normal_right.png');
      }
    }

    &.speaking {
      background-image: url('@{assets_url}old/oldbird_speaking.png');

      &.left {
        background-image: url('@{assets_url}old/oldbird_speaking_left.png');
      }

      &.right {
        background-image: url('@{assets_url}old/oldbird_speaking_right.png');
      }
    }

    &.pissed {
      background-image: url('@{assets_url}old/oldbird_pissed.png');

      &.speaking {
        background-image: url('@{assets_url}old/oldbird_speaking.png');
      }
    }

    &.shocked {
      background-image: url('@{assets_url}old/oldbird_shocked.png');
    }
  }

  &.oldlady {
    &.normal {
      background-image: url('@{assets_url}old/oldlady_normal.png');
      &.left {
        background-image: url('@{assets_url}old/oldlady_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}old/oldlady_normal_right.png');
      }
    }

    &.speaking {
      background-image: url('@{assets_url}old/oldlady_speaking.png');

      &.left {
        background-image: url('@{assets_url}old/oldlady_speaking_left.png');
      }

      &.right {
        background-image: url('@{assets_url}old/oldlady_speaking_right.png');
      }
    }

    &.pissed {
      background-image: url('@{assets_url}old/oldlady_pissed.png');

      &.speaking {
        background-image: url('@{assets_url}old/oldlady_speaking.png');
      }
    }
    &.shocked {
      background-image: url('@{assets_url}old/oldlady_shocked.png');
    }
  }
}

.politic, #bd_editor #scene .holder.bird-holder.politic{
  &.angry {background-image: url('@{assets_url}politic/angry.png'); }
  &.normal {background-image: url('@{assets_url}politic/normal.png');
    &.left {background-image: url('@{assets_url}politic/normal_left.png'); }
    &.right {background-image: url('@{assets_url}politic/normal_right.png'); }
  }
  &.shocked {background-image: url('@{assets_url}politic/shocked.png');}
  &.speaking {background-image: url('@{assets_url}politic/speaking.png');
    &.left {background-image: url('@{assets_url}politic/speaking_left.png'); }
    &.right {background-image: url('@{assets_url}politic/speaking_right.png'); }
  }
}

.halloween, #bd_editor #scene .holder.bird-holder.halloween {
  &.citrouille {
    &.angry {background-image: url('@{assets_url}halloween/citrouille_angry.png');
      &.speaking {background-image: url('@{assets_url}halloween/citrouille_angry_speaking.png'); }
    }
    &.normal {background-image: url('@{assets_url}halloween/citrouille_normal.png');
      &.left {background-image: url('@{assets_url}halloween/citrouille_normal_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/citrouille_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}halloween/citrouille_shocked.png');}
    &.speaking {background-image: url('@{assets_url}halloween/citrouille_speaking.png');
      &.left {background-image: url('@{assets_url}halloween/citrouille_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/citrouille_speaking_right.png'); }
    }
    &.scarying {background-image: url('@{assets_url}halloween/citrouille_scarying.png'); }
  }
  &.dracula {
    &.angry {background-image: url('@{assets_url}halloween/dracula_angry.png');
      &.speaking {background-image: url('@{assets_url}halloween/dracula_angry_speaking.png'); }
    }
    &.normal {background-image: url('@{assets_url}halloween/dracula_normal.png');
      &.left {background-image: url('@{assets_url}halloween/dracula_normal_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/dracula_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}halloween/dracula_shocked.png');}
    &.speaking {background-image: url('@{assets_url}halloween/dracula_speaking.png');
      &.left {background-image: url('@{assets_url}halloween/dracula_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/dracula_speaking_right.png'); }
    }
    &.scarying {background-image: url('@{assets_url}halloween/dracula_scarying.png'); }
  }
  &.frankenstein {
    &.angry {background-image: url('@{assets_url}halloween/frankenstein_angry.png');
      &.speaking {background-image: url('@{assets_url}halloween/frankenstein_angry_speaking.png'); }
    }
    &.normal {background-image: url('@{assets_url}halloween/frankenstein_normal.png');
      &.left {background-image: url('@{assets_url}halloween/frankenstein_normal_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/frankenstein_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}halloween/frankenstein_shocked.png');}
    &.speaking {background-image: url('@{assets_url}halloween/frankenstein_speaking.png');
      &.left {background-image: url('@{assets_url}halloween/frankenstein_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/frankenstein_speaking_right.png'); }
    }
    &.scarying {background-image: url('@{assets_url}halloween/frankenstein_scarying.png'); }
  }
  &.sorciere {
    &.angry {background-image: url('@{assets_url}halloween/sorciere_angry.png');
      &.speaking {background-image: url('@{assets_url}halloween/sorciere_angry_speaking.png'); }
    }
    &.normal {background-image: url('@{assets_url}halloween/sorciere_normal.png');
      &.left {background-image: url('@{assets_url}halloween/sorciere_normal_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/sorciere_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}halloween/sorciere_shocked.png');}
    &.speaking {background-image: url('@{assets_url}halloween/sorciere_speaking.png');
      &.left {background-image: url('@{assets_url}halloween/sorciere_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}halloween/sorciere_speaking_right.png'); }
    }
    &.scarying {background-image: url('@{assets_url}halloween/sorciere_scarying.png'); }
  }
}
.noel, #bd_editor #scene .holder.bird-holder.noel {
  &.cerf {
    &.angry {background-image: url('@{assets_url}noel/cerf_angry.png');}
    &.normal {background-image: url('@{assets_url}noel/cerf_normal.png');
      &.left {background-image: url('@{assets_url}noel/cerf_normal_left.png'); }
      &.right {background-image: url('@{assets_url}noel/cerf_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}noel/cerf_shocked.png');}
    &.speaking {background-image: url('@{assets_url}noel/cerf_speaking.png');
      &.left {background-image: url('@{assets_url}noel/cerf_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}noel/cerf_speaking_right.png'); }
    }
    &.tired {background-image: url('@{assets_url}noel/cerf_tired.png');}
  }
  &.gift {
    &.giving {
      &.left {background-image: url('@{assets_url}noel/gift_giving_left.png'); }
      &.right {background-image: url('@{assets_url}noel/gift_giving_right.png'); }
    }
    &.opening {background-image: url('@{assets_url}noel/gift_opening.png');
      &.angry {background-image: url('@{assets_url}noel/gift_opening_angry.png');}
      &.speaking {background-image: url('@{assets_url}noel/gift_opening_speaking.png');}
    }
  }
  &.party {
    &.normal {background-image: url('@{assets_url}noel/party_normal.png');
      &.left {background-image: url('@{assets_url}noel/party_normal_left.png'); }
      &.right {background-image: url('@{assets_url}noel/party_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}noel/party_speaking.png');
      &.left {background-image: url('@{assets_url}noel/party_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}noel/party_speaking_right.png'); }
    }
    &.throwing {
      &.left {background-image: url('@{assets_url}noel/party_throwing_left.png'); }
      &.right {background-image: url('@{assets_url}noel/party_throwing_right.png'); }
    }
  }
  &.santa {
    &.angry {background-image: url('@{assets_url}noel/santa_angry.png');}
    &.normal {background-image: url('@{assets_url}noel/santa_normal.png');
      &.left {background-image: url('@{assets_url}noel/santa_normal_left.png'); }
      &.right {background-image: url('@{assets_url}noel/santa_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}noel/santa_shocked.png');}
    &.speaking {background-image: url('@{assets_url}noel/santa_speaking.png');
      &.left {background-image: url('@{assets_url}noel/santa_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}noel/santa_speaking_right.png'); }
    }
    &.tired {background-image: url('@{assets_url}noel/santa_tired.png');}
  }
  &.winter {
    &.boy {
      &.angry {background-image: url('@{assets_url}noel/winter_boy_angry.png');}
      &.asking {background-image: url('@{assets_url}noel/winter_boy_asking.png');}
      &.crying {background-image: url('@{assets_url}noel/winter_boy_crying.png');}
      &.normal {
        &.left {background-image: url('@{assets_url}noel/winter_boy_normal_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_boy_normal_right.png'); }
      }
      &.speaking {background-image: url('@{assets_url}noel/winter_boy_speaking.png');
        &.left {background-image: url('@{assets_url}noel/winter_boy_speaking_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_boy_speaking_right.png'); }
      }
      &.sulk {background-image: url('@{assets_url}noel/winter_boy_sulk.png');}
      &.throwing {
        &.left {background-image: url('@{assets_url}noel/winter_boy_throwing_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_boy_throwing_right.png'); }
      }
    }
    &.girl {
      &.angry {background-image: url('@{assets_url}noel/winter_girl_angry.png');}
      &.asking {background-image: url('@{assets_url}noel/winter_girl_asking.png');}
      &.crying {background-image: url('@{assets_url}noel/winter_girl_crying.png');}
      &.normal {
        &.left {background-image: url('@{assets_url}noel/winter_girl_normal_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_girl_normal_right.png'); }
      }
      &.speaking {background-image: url('@{assets_url}noel/winter_girl_speaking.png');
        &.left {background-image: url('@{assets_url}noel/winter_girl_speaking_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_girl_speaking_right.png'); }
      }
      &.sulk {background-image: url('@{assets_url}noel/winter_girl_sulk.png');}
      &.throwing {
        &.left {background-image: url('@{assets_url}noel/winter_girl_throwing_left.png'); }
        &.right {background-image: url('@{assets_url}noel/winter_girl_throwing_right.png'); }
      }
    }
  }
}

.foot, #bd_editor #scene .holder.bird-holder.foot {
  &.arbitre {
    &.yellowcard {
      &.left {background-image: url('@{assets_url}foot/arbitre_yellowcard_left.png'); }
      &.right {background-image: url('@{assets_url}foot/arbitre_yellowcard_right.png'); }
    }
    &.redcard {
      &.left {background-image: url('@{assets_url}foot/arbitre_redcard_left.png'); }
      &.right {background-image: url('@{assets_url}foot/arbitre_redcard_right.png'); }
    }
  }
  &.ballon {background-image: url('@{assets_url}foot/ballon.png');
    &.flat {background-image: url('@{assets_url}foot/ballon_flat.png');}
  }
  &.supporter {
    &.singing {
      &.left {background-image: url('@{assets_url}foot/supporter_singing_left.png'); }
      &.right {background-image: url('@{assets_url}foot/supporter_singing_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}foot/supporter_shocked.png'); }
    &.shouting {background-image: url('@{assets_url}foot/supporter_shouting.png'); }
  }
  &.joueur {
    &.france {
      &.normal {background-image: url('@{assets_url}foot/joueur_france_normal.png');
        &.left {background-image: url('@{assets_url}foot/joueur_france_normal_left.png'); }
        &.right {background-image: url('@{assets_url}foot/joueur_france_normal_right.png'); }
      }
      &.speaking {background-image: url('@{assets_url}foot/joueur_france_speaking.png');
        &.left {background-image: url('@{assets_url}foot/joueur_france_speaking_left.png'); }
        &.right {background-image: url('@{assets_url}foot/joueur_france_speaking_right.png'); }
      }
      &.shocked {background-image: url('@{assets_url}foot/joueur_france_shocked.png'); }
    }
  }
}

.police, #bd_editor #scene .holder.bird-holder.police {
  &.officer {
    &.normal {background-image: url('@{assets_url}police/officer_normal.png');
      &.left {background-image: url('@{assets_url}police/officer_normal_left.png'); }
      &.right {background-image: url('@{assets_url}police/officer_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}police/officer_speaking.png');
      &.left {background-image: url('@{assets_url}police/officer_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}police/officer_speaking_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}police/officer_shocked.png'); }
  }
  &.chief {
    &.normal {background-image: url('@{assets_url}police/chief_normal.png');
      &.left {background-image: url('@{assets_url}police/chief_normal_left.png'); }
      &.right {background-image: url('@{assets_url}police/chief_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}police/chief_speaking.png');
      &.left {background-image: url('@{assets_url}police/chief_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}police/chief_speaking_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}police/chief_shocked.png'); }
  }
}

.hopital, #bd_editor #scene .holder.bird-holder.hopital {
  &.doctor {
    &.normal {background-image: url('@{assets_url}hopital/doctor_normal.png');
      &.left {background-image: url('@{assets_url}hopital/doctor_normal_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/doctor_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}hopital/doctor_speaking.png');
      &.left {background-image: url('@{assets_url}hopital/doctor_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/doctor_speaking_right.png'); }
    }
    &.turning {
      &.left {background-image: url('@{assets_url}hopital/doctor_turning_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/doctor_turning_right.png'); }
    }
    &.afraid {background-image: url('@{assets_url}hopital/doctor_afraid.png');
      &.speaking {background-image: url('@{assets_url}hopital/doctor_afraid_speaking.png');}
    }
  }
  &.nurse {
    &.normal {background-image: url('@{assets_url}hopital/nurse_normal.png');
      &.left {background-image: url('@{assets_url}hopital/nurse_normal_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/nurse_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}hopital/nurse_speaking.png');
      &.left {background-image: url('@{assets_url}hopital/nurse_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/nurse_speaking_right.png'); }
    }
    &.angry {background-image: url('@{assets_url}hopital/nurse_angry.png');
      &.speaking {background-image: url('@{assets_url}hopital/nurse_angry_speaking.png');}
    }
    &.shocked {background-image: url('@{assets_url}hopital/nurse_shocked.png'); }
  }
  &.patient {
    &.normal {background-image: url('@{assets_url}hopital/patient_normal.png');
      &.left {background-image: url('@{assets_url}hopital/patient_normal_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/patient_normal_right.png'); }
    }
    &.speaking {background-image: url('@{assets_url}hopital/patient_speaking.png');
      &.left {background-image: url('@{assets_url}hopital/patient_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}hopital/patient_speaking_right.png'); }
    }
    &.afraid {background-image: url('@{assets_url}hopital/patient_afraid.png');
      &.speaking {background-image: url('@{assets_url}hopital/patient_afraid_speaking.png');}
    }
    &.pissed {background-image: url('@{assets_url}hopital/patient_pissed.png');
      &.speaking {background-image: url('@{assets_url}hopital/patient_pissed_speaking.png');}
    }
  }
}

.summer, #bd_editor #scene .holder.bird-holder.summer {
  &.birds {
    &.normal {background-image: url('@{assets_url}summer/birds_normal.png');
      &.left {background-image: url('@{assets_url}summer/birds_normal_left.png'); }
      &.right {background-image: url('@{assets_url}summer/birds_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}summer/birds_shocked.png');}
    &.pissed {background-image: url('@{assets_url}summer/birds_pissed.png');
      &.speaking {background-image: url('@{assets_url}summer/birds_pissed_speaking.png');}
    }
    &.tired {background-image: url('@{assets_url}summer/birds_tired.png');
      &.speaking {background-image: url('@{assets_url}summer/birds_tired_speaking.png');}
    }
    &.speaking {background-image: url('@{assets_url}summer/birds_speaking.png');
      &.left {background-image: url('@{assets_url}summer/birds_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}summer/birds_speaking_right.png'); }
    }
  }
  &.lady {
    &.normal {background-image: url('@{assets_url}summer/lady_normal.png');
      &.left {background-image: url('@{assets_url}summer/lady_normal_left.png'); }
      &.right {background-image: url('@{assets_url}summer/lady_normal_right.png'); }
    }
    &.shocked {background-image: url('@{assets_url}summer/lady_shocked.png');}
    &.sleeping {background-image: url('@{assets_url}summer/lady_sleeping.png');}
    &.speaking {background-image: url('@{assets_url}summer/lady_speaking.png');
      &.left {background-image: url('@{assets_url}summer/lady_speaking_left.png'); }
      &.right {background-image: url('@{assets_url}summer/lady_speaking_right.png'); }
    }
  }
  &.baby {
    &.normal {
      background-image: url('@{assets_url}summer/baby_normal.png');

      &.left {
        background-image: url('@{assets_url}summer/baby_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}summer/baby_normal_right.png');
      }
    }

    &.shocked {
      background-image: url('@{assets_url}summer/baby_shocked.png');
    }

    &.pissed {
      background-image: url('@{assets_url}summer/baby_pissed.png');
    }

    &.angry {
      background-image: url('@{assets_url}summer/baby_angry.png');
    }

    &.crying {
      background-image: url('@{assets_url}summer/baby_crying.png');
    }

    &.speaking {
      background-image: url('@{assets_url}summer/baby_speaking.png');

      &.left {
        background-image: url('@{assets_url}summer/baby_speaking_left.png');
      }

      &.right {
        background-image: url('@{assets_url}summer/baby_speaking_right.png');
      }
    }
  }
}

.giletsjaunes, #bd_editor #scene .holder.bird-holder.giletsjaunes {
  &.birds {
    &.normal {
      background-image: url('@{assets_url}giletsjaunes/birds_normal.png');

      &.left {
        background-image: url('@{assets_url}giletsjaunes/birds_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}giletsjaunes/birds_normal_right.png');
      }
    }

    &.shocked {
      background-image: url('@{assets_url}giletsjaunes/birds_shocked.png');
    }

    &.pissed {
      background-image: url('@{assets_url}giletsjaunes/birds_pissed.png');

      &.speaking {
        background-image: url('@{assets_url}giletsjaunes/birds_pissed_speaking.png');
      }
    }

    &.speaking {
      background-image: url('@{assets_url}giletsjaunes/birds_speaking.png');

      &.left {
        background-image: url('@{assets_url}giletsjaunes/birds_speaking_left.png');
      }

      &.right {
        background-image: url('@{assets_url}giletsjaunes/birds_speaking_right.png');
      }
    }
  }

  &.lady {
    &.normal {
      background-image: url('@{assets_url}giletsjaunes/lady_normal.png');

      &.left {
        background-image: url('@{assets_url}giletsjaunes/lady_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}giletsjaunes/lady_normal_right.png');
      }
    }

    &.shocked {
      background-image: url('@{assets_url}giletsjaunes/lady_shocked.png');
    }

    &.pissed {
      background-image: url('@{assets_url}giletsjaunes/lady_pissed.png');

      &.speaking {
        background-image: url('@{assets_url}giletsjaunes/lady_pissed_speaking.png');
      }
    }

    &.speaking {
      background-image: url('@{assets_url}giletsjaunes/lady_speaking.png');

      &.left {
        background-image: url('@{assets_url}giletsjaunes/lady_speaking_left.png');
      }

      &.right {
        background-image: url('@{assets_url}giletsjaunes/lady_speaking_right.png');
      }
    }
  }
}

.masques, #bd_editor #scene .holder.bird-holder.masques {
  &.birds {
    &.normal {
      background-image: url('@{assets_url}masques/birds_normal.png');

      &.left {
        background-image: url('@{assets_url}masques/birds_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/birds_normal_right.png');
      }
    }
    &.turning {
      &.left {
        background-image: url('@{assets_url}masques/birds_turning_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/birds_turning_right.png');
      }
    }
    &.shocked {
      background-image: url('@{assets_url}masques/birds_shocked.png');
    }
    &.pissed {
      background-image: url('@{assets_url}masques/birds_pissed.png');
    }
  }
  &.lady {
    &.normal {
      background-image: url('@{assets_url}masques/lady_normal.png');

      &.left {
        background-image: url('@{assets_url}masques/lady_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/lady_normal_right.png');
      }
    }
    &.turning {
      &.left {
        background-image: url('@{assets_url}masques/lady_turning_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/lady_turning_right.png');
      }
    }
    &.shocked {
      background-image: url('@{assets_url}masques/lady_shocked.png');
    }
    &.pissed {
      background-image: url('@{assets_url}masques/lady_pissed.png');
    }
  }
  &.doctor {
    &.normal {
      background-image: url('@{assets_url}masques/doctor_normal.png');

      &.left {
        background-image: url('@{assets_url}masques/doctor_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/doctor_normal_right.png');
      }
    }
    &.turning {
      &.left {
        background-image: url('@{assets_url}masques/doctor_turning_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/doctor_turning_right.png');
      }
    }
    &.shocked {
      background-image: url('@{assets_url}masques/doctor_shocked.png');
    }
  }
  &.nurse {
    &.normal {
      background-image: url('@{assets_url}masques/nurse_normal.png');

      &.left {
        background-image: url('@{assets_url}masques/nurse_normal_left.png');
      }

      &.right {
        background-image: url('@{assets_url}masques/nurse_normal_right.png');
      }
    }
    &.shocked {
      background-image: url('@{assets_url}masques/nurse_shocked.png');
    }
    &.pissed {
      background-image: url('@{assets_url}masques/nurse_pissed.png');
    }
  }

}

.corbac, #bd_editor #scene .holder.bird-holder.corbac{
  &.normal {background-image: url('@{assets_url}corbac/normal.png');
    &.left {background-image: url('@{assets_url}corbac/normal_left.png');}
    &.right {background-image: url('@{assets_url}corbac/normal_right.png');}
  }
  &.shocked {background-image: url('@{assets_url}corbac/shocked.png');
    &.speaking { background-image: url('@{assets_url}corbac/shocked_speaking.png');}
  }
  &.speaking {background-image: url('@{assets_url}corbac/speaking.png');
    &.left {background-image: url('@{assets_url}corbac/speaking_left.png'); }
    &.right {background-image: url('@{assets_url}corbac/speaking_right.png'); }
  }
}

.thumb {
  &.bluesky {background: url('@{assets_url}icons/blue-sky_icon.png') no-repeat 50% 50%; }
  &.noel_day {background: url('@{assets_url}icons/noel_day.png') no-repeat 50% 50%; }
  &.noel_night {background: url('@{assets_url}icons/noel_night.png') no-repeat 50% 50%; }
  &.halloween_1 {background: url('@{assets_url}icons/halloween_lune.png') no-repeat 50% 50%; }
  &.halloween_2 {background: url('@{assets_url}icons/halloween_cimetiere.png') no-repeat 50% 50%; }
  &.halloween_3 {background: url('@{assets_url}icons/halloween_eclair.png') no-repeat 50% 50%; }
  &.summer {background: url('@{assets_url}icons/summer_icon.png') no-repeat 50% 50%; }

  &.sq_bluesky {background: url('@{assets_url}icons/blue-sky_icon.png') no-repeat 50% 50%; }
}
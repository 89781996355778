.Comments {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.Comments li .comment_author {
  font-weight: bold;
}
.Comments li .comment_date {
  margin-left: 0.5em;
  font-size: 0.8rem;
  color: #696968;
}
.Comments li .comment_content p {
  margin: 0 0 0.5em;
}

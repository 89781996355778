button.Liker {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;

  .count {
    font-weight: bolder;
  }

  .heart {
    font-size: 1.8rem;
    color: #868686;
  }

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }

  &.liked {
    .count {

    }

    .heart {
      color: #ed1a33;
    }
  }

  &.busy {
    opacity: .5;
  }
}
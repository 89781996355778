.App {
    background-color: #d9eafe;
    height: 100vh;
    overflow: auto;

    a.button,
    button {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: .5em;

        background-color: white;
        border: 1px solid #2e7cd4;
        border-radius: 0.2em;
        padding: 0.2em 0.4em;
        text-decoration: none;
        color: #333333;
        font-size: 1em;

        &:hover {
            background-color: #f1f1f1;
            cursor: pointer;
            color: #2e7cd4;
        }

        &:disabled,
        &:disabled:hover {
            background-color: white;
            border-color: #b7b7b7;
            color: #b7b7b7;
            cursor: not-allowed;
        }

        span.icon {
            font-weight: bold;
            font-size: 1em;
        }
    }

}
.Galleries {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}
.Galleries nav {
  padding-bottom: 1rem;
}
.Galleries nav div {
  border-radius: 2px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.Galleries nav div.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  cursor: pointer;
}
.Galleries nav div.icon.birds-icon {
  background: url('../../assets/icons/new_bird_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.news-icon {
  background: url('../../assets/icons/newspaper_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.lady-icon {
  background: url('../../assets/icons/lady_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.baby-icon {
  background: url('../../assets/icons/baby_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.old-icon {
  background: url('../../assets/icons/old_birds_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.politic-icon {
  background: url('../../assets/icons/politic_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.corbac-icon {
  background: url('../../assets/icons/corbac_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.foot-icon {
  background: url('../../assets/icons/foot_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.halloween-icon {
  background: url('../../assets/icons/halloween_citrouille.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.noel-icon {
  background: url('../../assets/icons/noel_papa.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.police-icon {
  background: url('../../assets/icons/police_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.hopital-icon {
  background: url('../../assets/icons/hopital_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.summer-icon {
  background: url('../../assets/icons/summer_birds_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.giletsjaunes-icon {
  background: url('../../assets/icons/giletsjaunes_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.masques-icon {
  background: url('../../assets/icons/masques_icon.png') no-repeat 50% 50%;
}
.Galleries nav div.icon.bubble-icon {
  background: url('../../assets/icons/bubble_icon2.png') no-repeat 50% 50%;
}
.Galleries nav div:hover {
  border-bottom: 2px solid #3A88E0;
}
.Galleries nav div.selected {
  border-bottom: 2px solid #3A88E0;
}
.Galleries .gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 824px;
}
.Galleries .gallery.background-gallery {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Galleries .gallery.background-gallery h2 {
  font-size: 1rem;
  margin: 0;
}
.Galleries .gallery .thumb {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  background-size: 150%;
  cursor: pointer;
}
.Galleries .gallery .thumb.selected {
  border-color: #1A2CED;
}
.Galleries .gallery .thumb:hover {
  background-color: #D2D5FA;
}
.Galleries .gallery .thumb.bubbles {
  background-color: #2c80dd;
  background-position: 50% 50%;
  background-size: 90%;
}
.Galleries .gallery .thumb.bubbles:hover {
  background-color: #2975cc;
}

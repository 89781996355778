.birds.unknown,
#bd_editor #scene .holder.bird-holder.unknown {
  background-image: url('../../assets/misc/unknown.png');
}
.birds.normal,
#bd_editor #scene .holder.bird-holder.normal {
  background-image: url('../../assets/birds/normal.png');
}
.birds.normal.left,
#bd_editor #scene .holder.bird-holder.normal.left {
  background-image: url('../../assets/birds/normal_left.png');
}
.birds.normal.right,
#bd_editor #scene .holder.bird-holder.normal.right {
  background-image: url('../../assets/birds/normal_right.png');
}
.birds.normal.down,
#bd_editor #scene .holder.bird-holder.normal.down {
  background-image: url('../../assets/birds/normal_down.png');
}
.birds.normal.down.left,
#bd_editor #scene .holder.bird-holder.normal.down.left {
  background-image: url('../../assets/birds/normal_down_left.png');
}
.birds.normal.down.right,
#bd_editor #scene .holder.bird-holder.normal.down.right {
  background-image: url('../../assets/birds/normal_down_right.png');
}
.birds.normal.up.left,
#bd_editor #scene .holder.bird-holder.normal.up.left {
  background-image: url('../../assets/birds/normal_up_left.png');
}
.birds.normal.up.right,
#bd_editor #scene .holder.bird-holder.normal.up.right {
  background-image: url('../../assets/birds/normal_up_right.png');
}
.birds.speaking,
#bd_editor #scene .holder.bird-holder.speaking {
  background-image: url('../../assets/birds/speaking.png');
}
.birds.speaking.left,
#bd_editor #scene .holder.bird-holder.speaking.left {
  background-image: url('../../assets/birds/speaking_left.png');
}
.birds.speaking.right,
#bd_editor #scene .holder.bird-holder.speaking.right {
  background-image: url('../../assets/birds/speaking_right.png');
}
.birds.mdr.left,
#bd_editor #scene .holder.bird-holder.mdr.left {
  background-image: url('../../assets/birds/mdr_left.png');
}
.birds.mdr.right,
#bd_editor #scene .holder.bird-holder.mdr.right {
  background-image: url('../../assets/birds/mdr_right.png');
}
.birds.afraid,
#bd_editor #scene .holder.bird-holder.afraid {
  background-image: url('../../assets/birds/afraid.png');
}
.birds.afraid.left,
#bd_editor #scene .holder.bird-holder.afraid.left {
  background-image: url('../../assets/birds/afraid_left.png');
}
.birds.afraid.right,
#bd_editor #scene .holder.bird-holder.afraid.right {
  background-image: url('../../assets/birds/afraid_right.png');
}
.birds.afraid.speaking,
#bd_editor #scene .holder.bird-holder.afraid.speaking {
  background-image: url('../../assets/birds/afraid_speaking.png');
}
.birds.afraid.speaking.left,
#bd_editor #scene .holder.bird-holder.afraid.speaking.left {
  background-image: url('../../assets/birds/afraid_speaking_left.png');
}
.birds.afraid.speaking.right,
#bd_editor #scene .holder.bird-holder.afraid.speaking.right {
  background-image: url('../../assets/birds/afraid_speaking_right.png');
}
.birds.amused.left,
#bd_editor #scene .holder.bird-holder.amused.left {
  background-image: url('../../assets/birds/amused_left.png');
}
.birds.amused.right,
#bd_editor #scene .holder.bird-holder.amused.right {
  background-image: url('../../assets/birds/amused_right.png');
}
.birds.bending.left,
#bd_editor #scene .holder.bird-holder.bending.left {
  background-image: url('../../assets/birds/bending_left.png');
}
.birds.bending.right,
#bd_editor #scene .holder.bird-holder.bending.right {
  background-image: url('../../assets/birds/bending_right.png');
}
.birds.blind.left,
#bd_editor #scene .holder.bird-holder.blind.left {
  background-image: url('../../assets/birds/blind_left.png');
}
.birds.blind.right,
#bd_editor #scene .holder.bird-holder.blind.right {
  background-image: url('../../assets/birds/blind_right.png');
}
.birds.sing.left,
#bd_editor #scene .holder.bird-holder.sing.left {
  background-image: url('../../assets/birds/sing_left.png');
}
.birds.sing.right,
#bd_editor #scene .holder.bird-holder.sing.right {
  background-image: url('../../assets/birds/sing_right.png');
}
.birds.crazy,
#bd_editor #scene .holder.bird-holder.crazy {
  background-image: url('../../assets/birds/crazy.png');
}
.birds.excusing.left,
#bd_editor #scene .holder.bird-holder.excusing.left {
  background-image: url('../../assets/birds/excusing_left.png');
}
.birds.excusing.right,
#bd_editor #scene .holder.bird-holder.excusing.right {
  background-image: url('../../assets/birds/excusing_right.png');
}
.birds.flap.left,
#bd_editor #scene .holder.bird-holder.flap.left {
  background-image: url('../../assets/birds/flap_left.png');
}
.birds.flap.right,
#bd_editor #scene .holder.bird-holder.flap.right {
  background-image: url('../../assets/birds/flap_right.png');
}
.birds.go.left,
#bd_editor #scene .holder.bird-holder.go.left {
  background-image: url('../../assets/birds/go_left.png');
}
.birds.go.right,
#bd_editor #scene .holder.bird-holder.go.right {
  background-image: url('../../assets/birds/go_right.png');
}
.birds.haughty,
#bd_editor #scene .holder.bird-holder.haughty {
  background-image: url('../../assets/birds/haughty.png');
}
.birds.haughty.speaking,
#bd_editor #scene .holder.bird-holder.haughty.speaking {
  background-image: url('../../assets/birds/haughty_speaking.png');
}
.birds.laughing.left,
#bd_editor #scene .holder.bird-holder.laughing.left {
  background-image: url('../../assets/birds/laughing_left.png');
}
.birds.laughing.right,
#bd_editor #scene .holder.bird-holder.laughing.right {
  background-image: url('../../assets/birds/laughing_right.png');
}
.birds.listening.left,
#bd_editor #scene .holder.bird-holder.listening.left {
  background-image: url('../../assets/birds/listening_left.png');
}
.birds.listening.right,
#bd_editor #scene .holder.bird-holder.listening.right {
  background-image: url('../../assets/birds/listening_right.png');
}
.birds.love,
#bd_editor #scene .holder.bird-holder.love {
  background-image: url('../../assets/birds/love.png');
}
.birds.love.left,
#bd_editor #scene .holder.bird-holder.love.left {
  background-image: url('../../assets/birds/love_left.png');
}
.birds.love.right,
#bd_editor #scene .holder.bird-holder.love.right {
  background-image: url('../../assets/birds/love_right.png');
}
.birds.pissed,
#bd_editor #scene .holder.bird-holder.pissed {
  background-image: url('../../assets/birds/pissed.png');
}
.birds.pissed.speaking,
#bd_editor #scene .holder.bird-holder.pissed.speaking {
  background-image: url('../../assets/birds/pissed_speaking.png');
}
.birds.whispering.left,
#bd_editor #scene .holder.bird-holder.whispering.left {
  background-image: url('../../assets/birds/whispering_left.png');
}
.birds.whispering.right,
#bd_editor #scene .holder.bird-holder.whispering.right {
  background-image: url('../../assets/birds/whispering_right.png');
}
.bubbles.little.left,
#bd_editor #scene .holder.bubble-holder.bubbles.little.left {
  background-image: url('../../assets/bubbles/little_left.png');
}
.bubbles.little.right,
#bd_editor #scene .holder.bubble-holder.bubbles.little.right {
  background-image: url('../../assets/bubbles/little_right.png');
}
.bubbles.big.left,
#bd_editor #scene .holder.bubble-holder.bubbles.big.left {
  background-image: url('../../assets/bubbles/big_left.png');
}
.bubbles.big.right,
#bd_editor #scene .holder.bubble-holder.bubbles.big.right {
  background-image: url('../../assets/bubbles/big_right.png');
}
.bubbles.thought.left,
#bd_editor #scene .holder.bubble-holder.bubbles.thought.left {
  background-image: url('../../assets/bubbles/thought_left.png');
}
.bubbles.thought.right,
#bd_editor #scene .holder.bubble-holder.bubbles.thought.right {
  background-image: url('../../assets/bubbles/thought_right.png');
}
.bubbles.left-right,
#bd_editor #scene .holder.bubble-holder.bubbles.left-right {
  background-image: url('../../assets/bubbles/left-right.png');
}
.bubbles.right-left,
#bd_editor #scene .holder.bubble-holder.bubbles.right-left {
  background-image: url('../../assets/bubbles/right-left.png');
}
.bubbles.full,
#bd_editor #scene .holder.bubble-holder.bubbles.full {
  background-image: url('../../assets/bubbles/full.png');
}
.bubbles.heart,
#bd_editor #scene .holder.bubble-holder.bubbles.heart {
  background-image: url('../../assets/bubbles/heart.png');
}
.bubbles.hearts,
#bd_editor #scene .holder.bubble-holder.bubbles.hearts {
  background-image: url('../../assets/bubbles/hearts.png');
}
.bubbles.christmas.santa.flying,
#bd_editor #scene .holder.bubble-holder.bubbles.christmas.santa.flying {
  background-image: url('../../assets/bubbles/christmas_santa_flying.png');
}
.news.afraid,
#bd_editor #scene .holder.bird-holder.news.afraid {
  background-image: url('../../assets/news/afraid.png');
}
.news.behindnewspaper,
#bd_editor #scene .holder.bird-holder.news.behindnewspaper {
  background-image: url('../../assets/news/behindnewspaper.png');
}
.news.normal,
#bd_editor #scene .holder.bird-holder.news.normal {
  background-image: url('../../assets/news/normal.png');
}
.news.normal.left,
#bd_editor #scene .holder.bird-holder.news.normal.left {
  background-image: url('../../assets/news/normal_left.png');
}
.news.normal.right,
#bd_editor #scene .holder.bird-holder.news.normal.right {
  background-image: url('../../assets/news/normal_right.png');
}
.news.pissed,
#bd_editor #scene .holder.bird-holder.news.pissed {
  background-image: url('../../assets/news/pissed.png');
}
.news.pissed.speaking,
#bd_editor #scene .holder.bird-holder.news.pissed.speaking {
  background-image: url('../../assets/news/pissed_speaking.png');
}
.news.speaking.left,
#bd_editor #scene .holder.bird-holder.news.speaking.left {
  background-image: url('../../assets/news/speaking_left.png');
}
.news.speaking.right,
#bd_editor #scene .holder.bird-holder.news.speaking.right {
  background-image: url('../../assets/news/speaking_right.png');
}
.news.lady.afraid,
#bd_editor #scene .holder.bird-holder.news.lady.afraid {
  background-image: url('../../assets/news/lady_afraid.png');
}
.news.lady.behindnewspaper,
#bd_editor #scene .holder.bird-holder.news.lady.behindnewspaper {
  background-image: url('../../assets/news/lady_behindnewspaper.png');
}
.news.lady.normal,
#bd_editor #scene .holder.bird-holder.news.lady.normal {
  background-image: url('../../assets/news/lady_normal.png');
}
.news.lady.normal.left,
#bd_editor #scene .holder.bird-holder.news.lady.normal.left {
  background-image: url('../../assets/news/lady_normal_left.png');
}
.news.lady.normal.right,
#bd_editor #scene .holder.bird-holder.news.lady.normal.right {
  background-image: url('../../assets/news/lady_normal_right.png');
}
.news.lady.speaking,
#bd_editor #scene .holder.bird-holder.news.lady.speaking {
  background-image: url('../../assets/news/lady_speaking.png');
}
.news.lady.speaking.left,
#bd_editor #scene .holder.bird-holder.news.lady.speaking.left {
  background-image: url('../../assets/news/lady_speaking_left.png');
}
.news.lady.speaking.right,
#bd_editor #scene .holder.bird-holder.news.lady.speaking.right {
  background-image: url('../../assets/news/lady_speaking_right.png');
}
.lady.normal,
#bd_editor #scene .holder.bird-holder.lady.normal {
  background-image: url('../../assets/lady/normal.png');
}
.lady.normal.left,
#bd_editor #scene .holder.bird-holder.lady.normal.left {
  background-image: url('../../assets/lady/normal_left.png');
}
.lady.normal.right,
#bd_editor #scene .holder.bird-holder.lady.normal.right {
  background-image: url('../../assets/lady/normal_right.png');
}
.lady.normal.up.left,
#bd_editor #scene .holder.bird-holder.lady.normal.up.left {
  background-image: url('../../assets/lady/normal_up_left.png');
}
.lady.normal.up.right,
#bd_editor #scene .holder.bird-holder.lady.normal.up.right {
  background-image: url('../../assets/lady/normal_up_right.png');
}
.lady.bending.left,
#bd_editor #scene .holder.bird-holder.lady.bending.left {
  background-image: url('../../assets/lady/bending_left.png');
}
.lady.bending.right,
#bd_editor #scene .holder.bird-holder.lady.bending.right {
  background-image: url('../../assets/lady/bending_right.png');
}
.lady.go.left,
#bd_editor #scene .holder.bird-holder.lady.go.left {
  background-image: url('../../assets/lady/go_left.png');
}
.lady.go.right,
#bd_editor #scene .holder.bird-holder.lady.go.right {
  background-image: url('../../assets/lady/go_right.png');
}
.lady.shocked,
#bd_editor #scene .holder.bird-holder.lady.shocked {
  background-image: url('../../assets/lady/shocked.png');
}
.lady.speaking,
#bd_editor #scene .holder.bird-holder.lady.speaking {
  background-image: url('../../assets/lady/speaking.png');
}
.lady.speaking.left,
#bd_editor #scene .holder.bird-holder.lady.speaking.left {
  background-image: url('../../assets/lady/speaking_left.png');
}
.lady.speaking.right,
#bd_editor #scene .holder.bird-holder.lady.speaking.right {
  background-image: url('../../assets/lady/speaking_right.png');
}
.lady.speaking.smiling.left,
#bd_editor #scene .holder.bird-holder.lady.speaking.smiling.left {
  background-image: url('../../assets/lady/speaking_smiling_left.png');
}
.lady.speaking.smiling.right,
#bd_editor #scene .holder.bird-holder.lady.speaking.smiling.right {
  background-image: url('../../assets/lady/speaking_smiling_right.png');
}
.lady.mdr.left,
#bd_editor #scene .holder.bird-holder.lady.mdr.left {
  background-image: url('../../assets/lady/mdr_left.png');
}
.lady.mdr.right,
#bd_editor #scene .holder.bird-holder.lady.mdr.right {
  background-image: url('../../assets/lady/mdr_right.png');
}
.lady.smile.left,
#bd_editor #scene .holder.bird-holder.lady.smile.left {
  background-image: url('../../assets/lady/smile_left.png');
}
.lady.smile.right,
#bd_editor #scene .holder.bird-holder.lady.smile.right {
  background-image: url('../../assets/lady/smile_right.png');
}
.lady.love.left,
#bd_editor #scene .holder.bird-holder.lady.love.left {
  background-image: url('../../assets/lady/love_left.png');
}
.lady.love.right,
#bd_editor #scene .holder.bird-holder.lady.love.right {
  background-image: url('../../assets/lady/love_right.png');
}
.lady.pissed,
#bd_editor #scene .holder.bird-holder.lady.pissed {
  background-image: url('../../assets/lady/pissed.png');
}
.lady.pissed.speaking,
#bd_editor #scene .holder.bird-holder.lady.pissed.speaking {
  background-image: url('../../assets/lady/pissed_speaking.png');
}
.lady.turning.left,
#bd_editor #scene .holder.bird-holder.lady.turning.left {
  background-image: url('../../assets/lady/turning_left.png');
}
.lady.turning.right,
#bd_editor #scene .holder.bird-holder.lady.turning.right {
  background-image: url('../../assets/lady/turning_right.png');
}
.lady.whispering.left,
#bd_editor #scene .holder.bird-holder.lady.whispering.left {
  background-image: url('../../assets/lady/whispering_left.png');
}
.lady.whispering.right,
#bd_editor #scene .holder.bird-holder.lady.whispering.right {
  background-image: url('../../assets/lady/whispering_right.png');
}
.baby.girl.normal.left,
#bd_editor #scene .holder.bird-holder.baby.girl.normal.left {
  background-image: url('../../assets/baby/girl_normal_left.png');
}
.baby.girl.normal.right,
#bd_editor #scene .holder.bird-holder.baby.girl.normal.right {
  background-image: url('../../assets/baby/girl_normal_right.png');
}
.baby.girl.speaking,
#bd_editor #scene .holder.bird-holder.baby.girl.speaking {
  background-image: url('../../assets/baby/girl_speaking.png');
}
.baby.girl.speaking.left,
#bd_editor #scene .holder.bird-holder.baby.girl.speaking.left {
  background-image: url('../../assets/baby/girl_speaking_left.png');
}
.baby.girl.speaking.right,
#bd_editor #scene .holder.bird-holder.baby.girl.speaking.right {
  background-image: url('../../assets/baby/girl_speaking_right.png');
}
.baby.girl.ask,
#bd_editor #scene .holder.bird-holder.baby.girl.ask {
  background-image: url('../../assets/baby/girl_ask.png');
}
.baby.girl.angry,
#bd_editor #scene .holder.bird-holder.baby.girl.angry {
  background-image: url('../../assets/baby/girl_angry.png');
}
.baby.girl.crying,
#bd_editor #scene .holder.bird-holder.baby.girl.crying {
  background-image: url('../../assets/baby/girl_crying.png');
}
.baby.girl.sulk,
#bd_editor #scene .holder.bird-holder.baby.girl.sulk {
  background-image: url('../../assets/baby/girl_sulk.png');
}
.baby.girl.amaze,
#bd_editor #scene .holder.bird-holder.baby.girl.amaze {
  background-image: url('../../assets/baby/girl_amaze.png');
}
.baby.boy.normal.left,
#bd_editor #scene .holder.bird-holder.baby.boy.normal.left {
  background-image: url('../../assets/baby/boy_normal_left.png');
}
.baby.boy.normal.right,
#bd_editor #scene .holder.bird-holder.baby.boy.normal.right {
  background-image: url('../../assets/baby/boy_normal_right.png');
}
.baby.boy.speaking,
#bd_editor #scene .holder.bird-holder.baby.boy.speaking {
  background-image: url('../../assets/baby/boy_speaking.png');
}
.baby.boy.speaking.left,
#bd_editor #scene .holder.bird-holder.baby.boy.speaking.left {
  background-image: url('../../assets/baby/boy_speaking_left.png');
}
.baby.boy.speaking.right,
#bd_editor #scene .holder.bird-holder.baby.boy.speaking.right {
  background-image: url('../../assets/baby/boy_speaking_right.png');
}
.baby.boy.ask,
#bd_editor #scene .holder.bird-holder.baby.boy.ask {
  background-image: url('../../assets/baby/boy_ask.png');
}
.baby.boy.angry,
#bd_editor #scene .holder.bird-holder.baby.boy.angry {
  background-image: url('../../assets/baby/boy_angry.png');
}
.baby.boy.crying,
#bd_editor #scene .holder.bird-holder.baby.boy.crying {
  background-image: url('../../assets/baby/boy_crying.png');
}
.baby.boy.sulk,
#bd_editor #scene .holder.bird-holder.baby.boy.sulk {
  background-image: url('../../assets/baby/boy_sulk.png');
}
.baby.boy.amaze,
#bd_editor #scene .holder.bird-holder.baby.boy.amaze {
  background-image: url('../../assets/baby/boy_amaze.png');
}
.old.oldbird.normal,
#bd_editor #scene .holder.bird-holder.old.oldbird.normal {
  background-image: url('../../assets/old/oldbird_normal.png');
}
.old.oldbird.normal.left,
#bd_editor #scene .holder.bird-holder.old.oldbird.normal.left {
  background-image: url('../../assets/old/oldbird_normal_left.png');
}
.old.oldbird.normal.right,
#bd_editor #scene .holder.bird-holder.old.oldbird.normal.right {
  background-image: url('../../assets/old/oldbird_normal_right.png');
}
.old.oldbird.speaking,
#bd_editor #scene .holder.bird-holder.old.oldbird.speaking {
  background-image: url('../../assets/old/oldbird_speaking.png');
}
.old.oldbird.speaking.left,
#bd_editor #scene .holder.bird-holder.old.oldbird.speaking.left {
  background-image: url('../../assets/old/oldbird_speaking_left.png');
}
.old.oldbird.speaking.right,
#bd_editor #scene .holder.bird-holder.old.oldbird.speaking.right {
  background-image: url('../../assets/old/oldbird_speaking_right.png');
}
.old.oldbird.pissed,
#bd_editor #scene .holder.bird-holder.old.oldbird.pissed {
  background-image: url('../../assets/old/oldbird_pissed.png');
}
.old.oldbird.pissed.speaking,
#bd_editor #scene .holder.bird-holder.old.oldbird.pissed.speaking {
  background-image: url('../../assets/old/oldbird_speaking.png');
}
.old.oldbird.shocked,
#bd_editor #scene .holder.bird-holder.old.oldbird.shocked {
  background-image: url('../../assets/old/oldbird_shocked.png');
}
.old.oldlady.normal,
#bd_editor #scene .holder.bird-holder.old.oldlady.normal {
  background-image: url('../../assets/old/oldlady_normal.png');
}
.old.oldlady.normal.left,
#bd_editor #scene .holder.bird-holder.old.oldlady.normal.left {
  background-image: url('../../assets/old/oldlady_normal_left.png');
}
.old.oldlady.normal.right,
#bd_editor #scene .holder.bird-holder.old.oldlady.normal.right {
  background-image: url('../../assets/old/oldlady_normal_right.png');
}
.old.oldlady.speaking,
#bd_editor #scene .holder.bird-holder.old.oldlady.speaking {
  background-image: url('../../assets/old/oldlady_speaking.png');
}
.old.oldlady.speaking.left,
#bd_editor #scene .holder.bird-holder.old.oldlady.speaking.left {
  background-image: url('../../assets/old/oldlady_speaking_left.png');
}
.old.oldlady.speaking.right,
#bd_editor #scene .holder.bird-holder.old.oldlady.speaking.right {
  background-image: url('../../assets/old/oldlady_speaking_right.png');
}
.old.oldlady.pissed,
#bd_editor #scene .holder.bird-holder.old.oldlady.pissed {
  background-image: url('../../assets/old/oldlady_pissed.png');
}
.old.oldlady.pissed.speaking,
#bd_editor #scene .holder.bird-holder.old.oldlady.pissed.speaking {
  background-image: url('../../assets/old/oldlady_speaking.png');
}
.old.oldlady.shocked,
#bd_editor #scene .holder.bird-holder.old.oldlady.shocked {
  background-image: url('../../assets/old/oldlady_shocked.png');
}
.politic.angry,
#bd_editor #scene .holder.bird-holder.politic.angry {
  background-image: url('../../assets/politic/angry.png');
}
.politic.normal,
#bd_editor #scene .holder.bird-holder.politic.normal {
  background-image: url('../../assets/politic/normal.png');
}
.politic.normal.left,
#bd_editor #scene .holder.bird-holder.politic.normal.left {
  background-image: url('../../assets/politic/normal_left.png');
}
.politic.normal.right,
#bd_editor #scene .holder.bird-holder.politic.normal.right {
  background-image: url('../../assets/politic/normal_right.png');
}
.politic.shocked,
#bd_editor #scene .holder.bird-holder.politic.shocked {
  background-image: url('../../assets/politic/shocked.png');
}
.politic.speaking,
#bd_editor #scene .holder.bird-holder.politic.speaking {
  background-image: url('../../assets/politic/speaking.png');
}
.politic.speaking.left,
#bd_editor #scene .holder.bird-holder.politic.speaking.left {
  background-image: url('../../assets/politic/speaking_left.png');
}
.politic.speaking.right,
#bd_editor #scene .holder.bird-holder.politic.speaking.right {
  background-image: url('../../assets/politic/speaking_right.png');
}
.halloween.citrouille.angry,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.angry {
  background-image: url('../../assets/halloween/citrouille_angry.png');
}
.halloween.citrouille.angry.speaking,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.angry.speaking {
  background-image: url('../../assets/halloween/citrouille_angry_speaking.png');
}
.halloween.citrouille.normal,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.normal {
  background-image: url('../../assets/halloween/citrouille_normal.png');
}
.halloween.citrouille.normal.left,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.normal.left {
  background-image: url('../../assets/halloween/citrouille_normal_left.png');
}
.halloween.citrouille.normal.right,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.normal.right {
  background-image: url('../../assets/halloween/citrouille_normal_right.png');
}
.halloween.citrouille.shocked,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.shocked {
  background-image: url('../../assets/halloween/citrouille_shocked.png');
}
.halloween.citrouille.speaking,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.speaking {
  background-image: url('../../assets/halloween/citrouille_speaking.png');
}
.halloween.citrouille.speaking.left,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.speaking.left {
  background-image: url('../../assets/halloween/citrouille_speaking_left.png');
}
.halloween.citrouille.speaking.right,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.speaking.right {
  background-image: url('../../assets/halloween/citrouille_speaking_right.png');
}
.halloween.citrouille.scarying,
#bd_editor #scene .holder.bird-holder.halloween.citrouille.scarying {
  background-image: url('../../assets/halloween/citrouille_scarying.png');
}
.halloween.dracula.angry,
#bd_editor #scene .holder.bird-holder.halloween.dracula.angry {
  background-image: url('../../assets/halloween/dracula_angry.png');
}
.halloween.dracula.angry.speaking,
#bd_editor #scene .holder.bird-holder.halloween.dracula.angry.speaking {
  background-image: url('../../assets/halloween/dracula_angry_speaking.png');
}
.halloween.dracula.normal,
#bd_editor #scene .holder.bird-holder.halloween.dracula.normal {
  background-image: url('../../assets/halloween/dracula_normal.png');
}
.halloween.dracula.normal.left,
#bd_editor #scene .holder.bird-holder.halloween.dracula.normal.left {
  background-image: url('../../assets/halloween/dracula_normal_left.png');
}
.halloween.dracula.normal.right,
#bd_editor #scene .holder.bird-holder.halloween.dracula.normal.right {
  background-image: url('../../assets/halloween/dracula_normal_right.png');
}
.halloween.dracula.shocked,
#bd_editor #scene .holder.bird-holder.halloween.dracula.shocked {
  background-image: url('../../assets/halloween/dracula_shocked.png');
}
.halloween.dracula.speaking,
#bd_editor #scene .holder.bird-holder.halloween.dracula.speaking {
  background-image: url('../../assets/halloween/dracula_speaking.png');
}
.halloween.dracula.speaking.left,
#bd_editor #scene .holder.bird-holder.halloween.dracula.speaking.left {
  background-image: url('../../assets/halloween/dracula_speaking_left.png');
}
.halloween.dracula.speaking.right,
#bd_editor #scene .holder.bird-holder.halloween.dracula.speaking.right {
  background-image: url('../../assets/halloween/dracula_speaking_right.png');
}
.halloween.dracula.scarying,
#bd_editor #scene .holder.bird-holder.halloween.dracula.scarying {
  background-image: url('../../assets/halloween/dracula_scarying.png');
}
.halloween.frankenstein.angry,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.angry {
  background-image: url('../../assets/halloween/frankenstein_angry.png');
}
.halloween.frankenstein.angry.speaking,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.angry.speaking {
  background-image: url('../../assets/halloween/frankenstein_angry_speaking.png');
}
.halloween.frankenstein.normal,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.normal {
  background-image: url('../../assets/halloween/frankenstein_normal.png');
}
.halloween.frankenstein.normal.left,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.normal.left {
  background-image: url('../../assets/halloween/frankenstein_normal_left.png');
}
.halloween.frankenstein.normal.right,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.normal.right {
  background-image: url('../../assets/halloween/frankenstein_normal_right.png');
}
.halloween.frankenstein.shocked,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.shocked {
  background-image: url('../../assets/halloween/frankenstein_shocked.png');
}
.halloween.frankenstein.speaking,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.speaking {
  background-image: url('../../assets/halloween/frankenstein_speaking.png');
}
.halloween.frankenstein.speaking.left,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.speaking.left {
  background-image: url('../../assets/halloween/frankenstein_speaking_left.png');
}
.halloween.frankenstein.speaking.right,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.speaking.right {
  background-image: url('../../assets/halloween/frankenstein_speaking_right.png');
}
.halloween.frankenstein.scarying,
#bd_editor #scene .holder.bird-holder.halloween.frankenstein.scarying {
  background-image: url('../../assets/halloween/frankenstein_scarying.png');
}
.halloween.sorciere.angry,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.angry {
  background-image: url('../../assets/halloween/sorciere_angry.png');
}
.halloween.sorciere.angry.speaking,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.angry.speaking {
  background-image: url('../../assets/halloween/sorciere_angry_speaking.png');
}
.halloween.sorciere.normal,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.normal {
  background-image: url('../../assets/halloween/sorciere_normal.png');
}
.halloween.sorciere.normal.left,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.normal.left {
  background-image: url('../../assets/halloween/sorciere_normal_left.png');
}
.halloween.sorciere.normal.right,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.normal.right {
  background-image: url('../../assets/halloween/sorciere_normal_right.png');
}
.halloween.sorciere.shocked,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.shocked {
  background-image: url('../../assets/halloween/sorciere_shocked.png');
}
.halloween.sorciere.speaking,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.speaking {
  background-image: url('../../assets/halloween/sorciere_speaking.png');
}
.halloween.sorciere.speaking.left,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.speaking.left {
  background-image: url('../../assets/halloween/sorciere_speaking_left.png');
}
.halloween.sorciere.speaking.right,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.speaking.right {
  background-image: url('../../assets/halloween/sorciere_speaking_right.png');
}
.halloween.sorciere.scarying,
#bd_editor #scene .holder.bird-holder.halloween.sorciere.scarying {
  background-image: url('../../assets/halloween/sorciere_scarying.png');
}
.noel.cerf.angry,
#bd_editor #scene .holder.bird-holder.noel.cerf.angry {
  background-image: url('../../assets/noel/cerf_angry.png');
}
.noel.cerf.normal,
#bd_editor #scene .holder.bird-holder.noel.cerf.normal {
  background-image: url('../../assets/noel/cerf_normal.png');
}
.noel.cerf.normal.left,
#bd_editor #scene .holder.bird-holder.noel.cerf.normal.left {
  background-image: url('../../assets/noel/cerf_normal_left.png');
}
.noel.cerf.normal.right,
#bd_editor #scene .holder.bird-holder.noel.cerf.normal.right {
  background-image: url('../../assets/noel/cerf_normal_right.png');
}
.noel.cerf.shocked,
#bd_editor #scene .holder.bird-holder.noel.cerf.shocked {
  background-image: url('../../assets/noel/cerf_shocked.png');
}
.noel.cerf.speaking,
#bd_editor #scene .holder.bird-holder.noel.cerf.speaking {
  background-image: url('../../assets/noel/cerf_speaking.png');
}
.noel.cerf.speaking.left,
#bd_editor #scene .holder.bird-holder.noel.cerf.speaking.left {
  background-image: url('../../assets/noel/cerf_speaking_left.png');
}
.noel.cerf.speaking.right,
#bd_editor #scene .holder.bird-holder.noel.cerf.speaking.right {
  background-image: url('../../assets/noel/cerf_speaking_right.png');
}
.noel.cerf.tired,
#bd_editor #scene .holder.bird-holder.noel.cerf.tired {
  background-image: url('../../assets/noel/cerf_tired.png');
}
.noel.gift.giving.left,
#bd_editor #scene .holder.bird-holder.noel.gift.giving.left {
  background-image: url('../../assets/noel/gift_giving_left.png');
}
.noel.gift.giving.right,
#bd_editor #scene .holder.bird-holder.noel.gift.giving.right {
  background-image: url('../../assets/noel/gift_giving_right.png');
}
.noel.gift.opening,
#bd_editor #scene .holder.bird-holder.noel.gift.opening {
  background-image: url('../../assets/noel/gift_opening.png');
}
.noel.gift.opening.angry,
#bd_editor #scene .holder.bird-holder.noel.gift.opening.angry {
  background-image: url('../../assets/noel/gift_opening_angry.png');
}
.noel.gift.opening.speaking,
#bd_editor #scene .holder.bird-holder.noel.gift.opening.speaking {
  background-image: url('../../assets/noel/gift_opening_speaking.png');
}
.noel.party.normal,
#bd_editor #scene .holder.bird-holder.noel.party.normal {
  background-image: url('../../assets/noel/party_normal.png');
}
.noel.party.normal.left,
#bd_editor #scene .holder.bird-holder.noel.party.normal.left {
  background-image: url('../../assets/noel/party_normal_left.png');
}
.noel.party.normal.right,
#bd_editor #scene .holder.bird-holder.noel.party.normal.right {
  background-image: url('../../assets/noel/party_normal_right.png');
}
.noel.party.speaking,
#bd_editor #scene .holder.bird-holder.noel.party.speaking {
  background-image: url('../../assets/noel/party_speaking.png');
}
.noel.party.speaking.left,
#bd_editor #scene .holder.bird-holder.noel.party.speaking.left {
  background-image: url('../../assets/noel/party_speaking_left.png');
}
.noel.party.speaking.right,
#bd_editor #scene .holder.bird-holder.noel.party.speaking.right {
  background-image: url('../../assets/noel/party_speaking_right.png');
}
.noel.party.throwing.left,
#bd_editor #scene .holder.bird-holder.noel.party.throwing.left {
  background-image: url('../../assets/noel/party_throwing_left.png');
}
.noel.party.throwing.right,
#bd_editor #scene .holder.bird-holder.noel.party.throwing.right {
  background-image: url('../../assets/noel/party_throwing_right.png');
}
.noel.santa.angry,
#bd_editor #scene .holder.bird-holder.noel.santa.angry {
  background-image: url('../../assets/noel/santa_angry.png');
}
.noel.santa.normal,
#bd_editor #scene .holder.bird-holder.noel.santa.normal {
  background-image: url('../../assets/noel/santa_normal.png');
}
.noel.santa.normal.left,
#bd_editor #scene .holder.bird-holder.noel.santa.normal.left {
  background-image: url('../../assets/noel/santa_normal_left.png');
}
.noel.santa.normal.right,
#bd_editor #scene .holder.bird-holder.noel.santa.normal.right {
  background-image: url('../../assets/noel/santa_normal_right.png');
}
.noel.santa.shocked,
#bd_editor #scene .holder.bird-holder.noel.santa.shocked {
  background-image: url('../../assets/noel/santa_shocked.png');
}
.noel.santa.speaking,
#bd_editor #scene .holder.bird-holder.noel.santa.speaking {
  background-image: url('../../assets/noel/santa_speaking.png');
}
.noel.santa.speaking.left,
#bd_editor #scene .holder.bird-holder.noel.santa.speaking.left {
  background-image: url('../../assets/noel/santa_speaking_left.png');
}
.noel.santa.speaking.right,
#bd_editor #scene .holder.bird-holder.noel.santa.speaking.right {
  background-image: url('../../assets/noel/santa_speaking_right.png');
}
.noel.santa.tired,
#bd_editor #scene .holder.bird-holder.noel.santa.tired {
  background-image: url('../../assets/noel/santa_tired.png');
}
.noel.winter.boy.angry,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.angry {
  background-image: url('../../assets/noel/winter_boy_angry.png');
}
.noel.winter.boy.asking,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.asking {
  background-image: url('../../assets/noel/winter_boy_asking.png');
}
.noel.winter.boy.crying,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.crying {
  background-image: url('../../assets/noel/winter_boy_crying.png');
}
.noel.winter.boy.normal.left,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.normal.left {
  background-image: url('../../assets/noel/winter_boy_normal_left.png');
}
.noel.winter.boy.normal.right,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.normal.right {
  background-image: url('../../assets/noel/winter_boy_normal_right.png');
}
.noel.winter.boy.speaking,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.speaking {
  background-image: url('../../assets/noel/winter_boy_speaking.png');
}
.noel.winter.boy.speaking.left,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.speaking.left {
  background-image: url('../../assets/noel/winter_boy_speaking_left.png');
}
.noel.winter.boy.speaking.right,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.speaking.right {
  background-image: url('../../assets/noel/winter_boy_speaking_right.png');
}
.noel.winter.boy.sulk,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.sulk {
  background-image: url('../../assets/noel/winter_boy_sulk.png');
}
.noel.winter.boy.throwing.left,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.throwing.left {
  background-image: url('../../assets/noel/winter_boy_throwing_left.png');
}
.noel.winter.boy.throwing.right,
#bd_editor #scene .holder.bird-holder.noel.winter.boy.throwing.right {
  background-image: url('../../assets/noel/winter_boy_throwing_right.png');
}
.noel.winter.girl.angry,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.angry {
  background-image: url('../../assets/noel/winter_girl_angry.png');
}
.noel.winter.girl.asking,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.asking {
  background-image: url('../../assets/noel/winter_girl_asking.png');
}
.noel.winter.girl.crying,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.crying {
  background-image: url('../../assets/noel/winter_girl_crying.png');
}
.noel.winter.girl.normal.left,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.normal.left {
  background-image: url('../../assets/noel/winter_girl_normal_left.png');
}
.noel.winter.girl.normal.right,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.normal.right {
  background-image: url('../../assets/noel/winter_girl_normal_right.png');
}
.noel.winter.girl.speaking,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.speaking {
  background-image: url('../../assets/noel/winter_girl_speaking.png');
}
.noel.winter.girl.speaking.left,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.speaking.left {
  background-image: url('../../assets/noel/winter_girl_speaking_left.png');
}
.noel.winter.girl.speaking.right,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.speaking.right {
  background-image: url('../../assets/noel/winter_girl_speaking_right.png');
}
.noel.winter.girl.sulk,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.sulk {
  background-image: url('../../assets/noel/winter_girl_sulk.png');
}
.noel.winter.girl.throwing.left,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.throwing.left {
  background-image: url('../../assets/noel/winter_girl_throwing_left.png');
}
.noel.winter.girl.throwing.right,
#bd_editor #scene .holder.bird-holder.noel.winter.girl.throwing.right {
  background-image: url('../../assets/noel/winter_girl_throwing_right.png');
}
.foot.arbitre.yellowcard.left,
#bd_editor #scene .holder.bird-holder.foot.arbitre.yellowcard.left {
  background-image: url('../../assets/foot/arbitre_yellowcard_left.png');
}
.foot.arbitre.yellowcard.right,
#bd_editor #scene .holder.bird-holder.foot.arbitre.yellowcard.right {
  background-image: url('../../assets/foot/arbitre_yellowcard_right.png');
}
.foot.arbitre.redcard.left,
#bd_editor #scene .holder.bird-holder.foot.arbitre.redcard.left {
  background-image: url('../../assets/foot/arbitre_redcard_left.png');
}
.foot.arbitre.redcard.right,
#bd_editor #scene .holder.bird-holder.foot.arbitre.redcard.right {
  background-image: url('../../assets/foot/arbitre_redcard_right.png');
}
.foot.ballon,
#bd_editor #scene .holder.bird-holder.foot.ballon {
  background-image: url('../../assets/foot/ballon.png');
}
.foot.ballon.flat,
#bd_editor #scene .holder.bird-holder.foot.ballon.flat {
  background-image: url('../../assets/foot/ballon_flat.png');
}
.foot.supporter.singing.left,
#bd_editor #scene .holder.bird-holder.foot.supporter.singing.left {
  background-image: url('../../assets/foot/supporter_singing_left.png');
}
.foot.supporter.singing.right,
#bd_editor #scene .holder.bird-holder.foot.supporter.singing.right {
  background-image: url('../../assets/foot/supporter_singing_right.png');
}
.foot.supporter.shocked,
#bd_editor #scene .holder.bird-holder.foot.supporter.shocked {
  background-image: url('../../assets/foot/supporter_shocked.png');
}
.foot.supporter.shouting,
#bd_editor #scene .holder.bird-holder.foot.supporter.shouting {
  background-image: url('../../assets/foot/supporter_shouting.png');
}
.foot.joueur.france.normal,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.normal {
  background-image: url('../../assets/foot/joueur_france_normal.png');
}
.foot.joueur.france.normal.left,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.normal.left {
  background-image: url('../../assets/foot/joueur_france_normal_left.png');
}
.foot.joueur.france.normal.right,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.normal.right {
  background-image: url('../../assets/foot/joueur_france_normal_right.png');
}
.foot.joueur.france.speaking,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.speaking {
  background-image: url('../../assets/foot/joueur_france_speaking.png');
}
.foot.joueur.france.speaking.left,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.speaking.left {
  background-image: url('../../assets/foot/joueur_france_speaking_left.png');
}
.foot.joueur.france.speaking.right,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.speaking.right {
  background-image: url('../../assets/foot/joueur_france_speaking_right.png');
}
.foot.joueur.france.shocked,
#bd_editor #scene .holder.bird-holder.foot.joueur.france.shocked {
  background-image: url('../../assets/foot/joueur_france_shocked.png');
}
.police.officer.normal,
#bd_editor #scene .holder.bird-holder.police.officer.normal {
  background-image: url('../../assets/police/officer_normal.png');
}
.police.officer.normal.left,
#bd_editor #scene .holder.bird-holder.police.officer.normal.left {
  background-image: url('../../assets/police/officer_normal_left.png');
}
.police.officer.normal.right,
#bd_editor #scene .holder.bird-holder.police.officer.normal.right {
  background-image: url('../../assets/police/officer_normal_right.png');
}
.police.officer.speaking,
#bd_editor #scene .holder.bird-holder.police.officer.speaking {
  background-image: url('../../assets/police/officer_speaking.png');
}
.police.officer.speaking.left,
#bd_editor #scene .holder.bird-holder.police.officer.speaking.left {
  background-image: url('../../assets/police/officer_speaking_left.png');
}
.police.officer.speaking.right,
#bd_editor #scene .holder.bird-holder.police.officer.speaking.right {
  background-image: url('../../assets/police/officer_speaking_right.png');
}
.police.officer.shocked,
#bd_editor #scene .holder.bird-holder.police.officer.shocked {
  background-image: url('../../assets/police/officer_shocked.png');
}
.police.chief.normal,
#bd_editor #scene .holder.bird-holder.police.chief.normal {
  background-image: url('../../assets/police/chief_normal.png');
}
.police.chief.normal.left,
#bd_editor #scene .holder.bird-holder.police.chief.normal.left {
  background-image: url('../../assets/police/chief_normal_left.png');
}
.police.chief.normal.right,
#bd_editor #scene .holder.bird-holder.police.chief.normal.right {
  background-image: url('../../assets/police/chief_normal_right.png');
}
.police.chief.speaking,
#bd_editor #scene .holder.bird-holder.police.chief.speaking {
  background-image: url('../../assets/police/chief_speaking.png');
}
.police.chief.speaking.left,
#bd_editor #scene .holder.bird-holder.police.chief.speaking.left {
  background-image: url('../../assets/police/chief_speaking_left.png');
}
.police.chief.speaking.right,
#bd_editor #scene .holder.bird-holder.police.chief.speaking.right {
  background-image: url('../../assets/police/chief_speaking_right.png');
}
.police.chief.shocked,
#bd_editor #scene .holder.bird-holder.police.chief.shocked {
  background-image: url('../../assets/police/chief_shocked.png');
}
.hopital.doctor.normal,
#bd_editor #scene .holder.bird-holder.hopital.doctor.normal {
  background-image: url('../../assets/hopital/doctor_normal.png');
}
.hopital.doctor.normal.left,
#bd_editor #scene .holder.bird-holder.hopital.doctor.normal.left {
  background-image: url('../../assets/hopital/doctor_normal_left.png');
}
.hopital.doctor.normal.right,
#bd_editor #scene .holder.bird-holder.hopital.doctor.normal.right {
  background-image: url('../../assets/hopital/doctor_normal_right.png');
}
.hopital.doctor.speaking,
#bd_editor #scene .holder.bird-holder.hopital.doctor.speaking {
  background-image: url('../../assets/hopital/doctor_speaking.png');
}
.hopital.doctor.speaking.left,
#bd_editor #scene .holder.bird-holder.hopital.doctor.speaking.left {
  background-image: url('../../assets/hopital/doctor_speaking_left.png');
}
.hopital.doctor.speaking.right,
#bd_editor #scene .holder.bird-holder.hopital.doctor.speaking.right {
  background-image: url('../../assets/hopital/doctor_speaking_right.png');
}
.hopital.doctor.turning.left,
#bd_editor #scene .holder.bird-holder.hopital.doctor.turning.left {
  background-image: url('../../assets/hopital/doctor_turning_left.png');
}
.hopital.doctor.turning.right,
#bd_editor #scene .holder.bird-holder.hopital.doctor.turning.right {
  background-image: url('../../assets/hopital/doctor_turning_right.png');
}
.hopital.doctor.afraid,
#bd_editor #scene .holder.bird-holder.hopital.doctor.afraid {
  background-image: url('../../assets/hopital/doctor_afraid.png');
}
.hopital.doctor.afraid.speaking,
#bd_editor #scene .holder.bird-holder.hopital.doctor.afraid.speaking {
  background-image: url('../../assets/hopital/doctor_afraid_speaking.png');
}
.hopital.nurse.normal,
#bd_editor #scene .holder.bird-holder.hopital.nurse.normal {
  background-image: url('../../assets/hopital/nurse_normal.png');
}
.hopital.nurse.normal.left,
#bd_editor #scene .holder.bird-holder.hopital.nurse.normal.left {
  background-image: url('../../assets/hopital/nurse_normal_left.png');
}
.hopital.nurse.normal.right,
#bd_editor #scene .holder.bird-holder.hopital.nurse.normal.right {
  background-image: url('../../assets/hopital/nurse_normal_right.png');
}
.hopital.nurse.speaking,
#bd_editor #scene .holder.bird-holder.hopital.nurse.speaking {
  background-image: url('../../assets/hopital/nurse_speaking.png');
}
.hopital.nurse.speaking.left,
#bd_editor #scene .holder.bird-holder.hopital.nurse.speaking.left {
  background-image: url('../../assets/hopital/nurse_speaking_left.png');
}
.hopital.nurse.speaking.right,
#bd_editor #scene .holder.bird-holder.hopital.nurse.speaking.right {
  background-image: url('../../assets/hopital/nurse_speaking_right.png');
}
.hopital.nurse.angry,
#bd_editor #scene .holder.bird-holder.hopital.nurse.angry {
  background-image: url('../../assets/hopital/nurse_angry.png');
}
.hopital.nurse.angry.speaking,
#bd_editor #scene .holder.bird-holder.hopital.nurse.angry.speaking {
  background-image: url('../../assets/hopital/nurse_angry_speaking.png');
}
.hopital.nurse.shocked,
#bd_editor #scene .holder.bird-holder.hopital.nurse.shocked {
  background-image: url('../../assets/hopital/nurse_shocked.png');
}
.hopital.patient.normal,
#bd_editor #scene .holder.bird-holder.hopital.patient.normal {
  background-image: url('../../assets/hopital/patient_normal.png');
}
.hopital.patient.normal.left,
#bd_editor #scene .holder.bird-holder.hopital.patient.normal.left {
  background-image: url('../../assets/hopital/patient_normal_left.png');
}
.hopital.patient.normal.right,
#bd_editor #scene .holder.bird-holder.hopital.patient.normal.right {
  background-image: url('../../assets/hopital/patient_normal_right.png');
}
.hopital.patient.speaking,
#bd_editor #scene .holder.bird-holder.hopital.patient.speaking {
  background-image: url('../../assets/hopital/patient_speaking.png');
}
.hopital.patient.speaking.left,
#bd_editor #scene .holder.bird-holder.hopital.patient.speaking.left {
  background-image: url('../../assets/hopital/patient_speaking_left.png');
}
.hopital.patient.speaking.right,
#bd_editor #scene .holder.bird-holder.hopital.patient.speaking.right {
  background-image: url('../../assets/hopital/patient_speaking_right.png');
}
.hopital.patient.afraid,
#bd_editor #scene .holder.bird-holder.hopital.patient.afraid {
  background-image: url('../../assets/hopital/patient_afraid.png');
}
.hopital.patient.afraid.speaking,
#bd_editor #scene .holder.bird-holder.hopital.patient.afraid.speaking {
  background-image: url('../../assets/hopital/patient_afraid_speaking.png');
}
.hopital.patient.pissed,
#bd_editor #scene .holder.bird-holder.hopital.patient.pissed {
  background-image: url('../../assets/hopital/patient_pissed.png');
}
.hopital.patient.pissed.speaking,
#bd_editor #scene .holder.bird-holder.hopital.patient.pissed.speaking {
  background-image: url('../../assets/hopital/patient_pissed_speaking.png');
}
.summer.birds.normal,
#bd_editor #scene .holder.bird-holder.summer.birds.normal {
  background-image: url('../../assets/summer/birds_normal.png');
}
.summer.birds.normal.left,
#bd_editor #scene .holder.bird-holder.summer.birds.normal.left {
  background-image: url('../../assets/summer/birds_normal_left.png');
}
.summer.birds.normal.right,
#bd_editor #scene .holder.bird-holder.summer.birds.normal.right {
  background-image: url('../../assets/summer/birds_normal_right.png');
}
.summer.birds.shocked,
#bd_editor #scene .holder.bird-holder.summer.birds.shocked {
  background-image: url('../../assets/summer/birds_shocked.png');
}
.summer.birds.pissed,
#bd_editor #scene .holder.bird-holder.summer.birds.pissed {
  background-image: url('../../assets/summer/birds_pissed.png');
}
.summer.birds.pissed.speaking,
#bd_editor #scene .holder.bird-holder.summer.birds.pissed.speaking {
  background-image: url('../../assets/summer/birds_pissed_speaking.png');
}
.summer.birds.tired,
#bd_editor #scene .holder.bird-holder.summer.birds.tired {
  background-image: url('../../assets/summer/birds_tired.png');
}
.summer.birds.tired.speaking,
#bd_editor #scene .holder.bird-holder.summer.birds.tired.speaking {
  background-image: url('../../assets/summer/birds_tired_speaking.png');
}
.summer.birds.speaking,
#bd_editor #scene .holder.bird-holder.summer.birds.speaking {
  background-image: url('../../assets/summer/birds_speaking.png');
}
.summer.birds.speaking.left,
#bd_editor #scene .holder.bird-holder.summer.birds.speaking.left {
  background-image: url('../../assets/summer/birds_speaking_left.png');
}
.summer.birds.speaking.right,
#bd_editor #scene .holder.bird-holder.summer.birds.speaking.right {
  background-image: url('../../assets/summer/birds_speaking_right.png');
}
.summer.lady.normal,
#bd_editor #scene .holder.bird-holder.summer.lady.normal {
  background-image: url('../../assets/summer/lady_normal.png');
}
.summer.lady.normal.left,
#bd_editor #scene .holder.bird-holder.summer.lady.normal.left {
  background-image: url('../../assets/summer/lady_normal_left.png');
}
.summer.lady.normal.right,
#bd_editor #scene .holder.bird-holder.summer.lady.normal.right {
  background-image: url('../../assets/summer/lady_normal_right.png');
}
.summer.lady.shocked,
#bd_editor #scene .holder.bird-holder.summer.lady.shocked {
  background-image: url('../../assets/summer/lady_shocked.png');
}
.summer.lady.sleeping,
#bd_editor #scene .holder.bird-holder.summer.lady.sleeping {
  background-image: url('../../assets/summer/lady_sleeping.png');
}
.summer.lady.speaking,
#bd_editor #scene .holder.bird-holder.summer.lady.speaking {
  background-image: url('../../assets/summer/lady_speaking.png');
}
.summer.lady.speaking.left,
#bd_editor #scene .holder.bird-holder.summer.lady.speaking.left {
  background-image: url('../../assets/summer/lady_speaking_left.png');
}
.summer.lady.speaking.right,
#bd_editor #scene .holder.bird-holder.summer.lady.speaking.right {
  background-image: url('../../assets/summer/lady_speaking_right.png');
}
.summer.baby.normal,
#bd_editor #scene .holder.bird-holder.summer.baby.normal {
  background-image: url('../../assets/summer/baby_normal.png');
}
.summer.baby.normal.left,
#bd_editor #scene .holder.bird-holder.summer.baby.normal.left {
  background-image: url('../../assets/summer/baby_normal_left.png');
}
.summer.baby.normal.right,
#bd_editor #scene .holder.bird-holder.summer.baby.normal.right {
  background-image: url('../../assets/summer/baby_normal_right.png');
}
.summer.baby.shocked,
#bd_editor #scene .holder.bird-holder.summer.baby.shocked {
  background-image: url('../../assets/summer/baby_shocked.png');
}
.summer.baby.pissed,
#bd_editor #scene .holder.bird-holder.summer.baby.pissed {
  background-image: url('../../assets/summer/baby_pissed.png');
}
.summer.baby.angry,
#bd_editor #scene .holder.bird-holder.summer.baby.angry {
  background-image: url('../../assets/summer/baby_angry.png');
}
.summer.baby.crying,
#bd_editor #scene .holder.bird-holder.summer.baby.crying {
  background-image: url('../../assets/summer/baby_crying.png');
}
.summer.baby.speaking,
#bd_editor #scene .holder.bird-holder.summer.baby.speaking {
  background-image: url('../../assets/summer/baby_speaking.png');
}
.summer.baby.speaking.left,
#bd_editor #scene .holder.bird-holder.summer.baby.speaking.left {
  background-image: url('../../assets/summer/baby_speaking_left.png');
}
.summer.baby.speaking.right,
#bd_editor #scene .holder.bird-holder.summer.baby.speaking.right {
  background-image: url('../../assets/summer/baby_speaking_right.png');
}
.giletsjaunes.birds.normal,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.normal {
  background-image: url('../../assets/giletsjaunes/birds_normal.png');
}
.giletsjaunes.birds.normal.left,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.normal.left {
  background-image: url('../../assets/giletsjaunes/birds_normal_left.png');
}
.giletsjaunes.birds.normal.right,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.normal.right {
  background-image: url('../../assets/giletsjaunes/birds_normal_right.png');
}
.giletsjaunes.birds.shocked,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.shocked {
  background-image: url('../../assets/giletsjaunes/birds_shocked.png');
}
.giletsjaunes.birds.pissed,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.pissed {
  background-image: url('../../assets/giletsjaunes/birds_pissed.png');
}
.giletsjaunes.birds.pissed.speaking,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.pissed.speaking {
  background-image: url('../../assets/giletsjaunes/birds_pissed_speaking.png');
}
.giletsjaunes.birds.speaking,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.speaking {
  background-image: url('../../assets/giletsjaunes/birds_speaking.png');
}
.giletsjaunes.birds.speaking.left,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.speaking.left {
  background-image: url('../../assets/giletsjaunes/birds_speaking_left.png');
}
.giletsjaunes.birds.speaking.right,
#bd_editor #scene .holder.bird-holder.giletsjaunes.birds.speaking.right {
  background-image: url('../../assets/giletsjaunes/birds_speaking_right.png');
}
.giletsjaunes.lady.normal,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.normal {
  background-image: url('../../assets/giletsjaunes/lady_normal.png');
}
.giletsjaunes.lady.normal.left,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.normal.left {
  background-image: url('../../assets/giletsjaunes/lady_normal_left.png');
}
.giletsjaunes.lady.normal.right,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.normal.right {
  background-image: url('../../assets/giletsjaunes/lady_normal_right.png');
}
.giletsjaunes.lady.shocked,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.shocked {
  background-image: url('../../assets/giletsjaunes/lady_shocked.png');
}
.giletsjaunes.lady.pissed,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.pissed {
  background-image: url('../../assets/giletsjaunes/lady_pissed.png');
}
.giletsjaunes.lady.pissed.speaking,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.pissed.speaking {
  background-image: url('../../assets/giletsjaunes/lady_pissed_speaking.png');
}
.giletsjaunes.lady.speaking,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.speaking {
  background-image: url('../../assets/giletsjaunes/lady_speaking.png');
}
.giletsjaunes.lady.speaking.left,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.speaking.left {
  background-image: url('../../assets/giletsjaunes/lady_speaking_left.png');
}
.giletsjaunes.lady.speaking.right,
#bd_editor #scene .holder.bird-holder.giletsjaunes.lady.speaking.right {
  background-image: url('../../assets/giletsjaunes/lady_speaking_right.png');
}
.masques.birds.normal,
#bd_editor #scene .holder.bird-holder.masques.birds.normal {
  background-image: url('../../assets/masques/birds_normal.png');
}
.masques.birds.normal.left,
#bd_editor #scene .holder.bird-holder.masques.birds.normal.left {
  background-image: url('../../assets/masques/birds_normal_left.png');
}
.masques.birds.normal.right,
#bd_editor #scene .holder.bird-holder.masques.birds.normal.right {
  background-image: url('../../assets/masques/birds_normal_right.png');
}
.masques.birds.turning.left,
#bd_editor #scene .holder.bird-holder.masques.birds.turning.left {
  background-image: url('../../assets/masques/birds_turning_left.png');
}
.masques.birds.turning.right,
#bd_editor #scene .holder.bird-holder.masques.birds.turning.right {
  background-image: url('../../assets/masques/birds_turning_right.png');
}
.masques.birds.shocked,
#bd_editor #scene .holder.bird-holder.masques.birds.shocked {
  background-image: url('../../assets/masques/birds_shocked.png');
}
.masques.birds.pissed,
#bd_editor #scene .holder.bird-holder.masques.birds.pissed {
  background-image: url('../../assets/masques/birds_pissed.png');
}
.masques.lady.normal,
#bd_editor #scene .holder.bird-holder.masques.lady.normal {
  background-image: url('../../assets/masques/lady_normal.png');
}
.masques.lady.normal.left,
#bd_editor #scene .holder.bird-holder.masques.lady.normal.left {
  background-image: url('../../assets/masques/lady_normal_left.png');
}
.masques.lady.normal.right,
#bd_editor #scene .holder.bird-holder.masques.lady.normal.right {
  background-image: url('../../assets/masques/lady_normal_right.png');
}
.masques.lady.turning.left,
#bd_editor #scene .holder.bird-holder.masques.lady.turning.left {
  background-image: url('../../assets/masques/lady_turning_left.png');
}
.masques.lady.turning.right,
#bd_editor #scene .holder.bird-holder.masques.lady.turning.right {
  background-image: url('../../assets/masques/lady_turning_right.png');
}
.masques.lady.shocked,
#bd_editor #scene .holder.bird-holder.masques.lady.shocked {
  background-image: url('../../assets/masques/lady_shocked.png');
}
.masques.lady.pissed,
#bd_editor #scene .holder.bird-holder.masques.lady.pissed {
  background-image: url('../../assets/masques/lady_pissed.png');
}
.masques.doctor.normal,
#bd_editor #scene .holder.bird-holder.masques.doctor.normal {
  background-image: url('../../assets/masques/doctor_normal.png');
}
.masques.doctor.normal.left,
#bd_editor #scene .holder.bird-holder.masques.doctor.normal.left {
  background-image: url('../../assets/masques/doctor_normal_left.png');
}
.masques.doctor.normal.right,
#bd_editor #scene .holder.bird-holder.masques.doctor.normal.right {
  background-image: url('../../assets/masques/doctor_normal_right.png');
}
.masques.doctor.turning.left,
#bd_editor #scene .holder.bird-holder.masques.doctor.turning.left {
  background-image: url('../../assets/masques/doctor_turning_left.png');
}
.masques.doctor.turning.right,
#bd_editor #scene .holder.bird-holder.masques.doctor.turning.right {
  background-image: url('../../assets/masques/doctor_turning_right.png');
}
.masques.doctor.shocked,
#bd_editor #scene .holder.bird-holder.masques.doctor.shocked {
  background-image: url('../../assets/masques/doctor_shocked.png');
}
.masques.nurse.normal,
#bd_editor #scene .holder.bird-holder.masques.nurse.normal {
  background-image: url('../../assets/masques/nurse_normal.png');
}
.masques.nurse.normal.left,
#bd_editor #scene .holder.bird-holder.masques.nurse.normal.left {
  background-image: url('../../assets/masques/nurse_normal_left.png');
}
.masques.nurse.normal.right,
#bd_editor #scene .holder.bird-holder.masques.nurse.normal.right {
  background-image: url('../../assets/masques/nurse_normal_right.png');
}
.masques.nurse.shocked,
#bd_editor #scene .holder.bird-holder.masques.nurse.shocked {
  background-image: url('../../assets/masques/nurse_shocked.png');
}
.masques.nurse.pissed,
#bd_editor #scene .holder.bird-holder.masques.nurse.pissed {
  background-image: url('../../assets/masques/nurse_pissed.png');
}
.corbac.normal,
#bd_editor #scene .holder.bird-holder.corbac.normal {
  background-image: url('../../assets/corbac/normal.png');
}
.corbac.normal.left,
#bd_editor #scene .holder.bird-holder.corbac.normal.left {
  background-image: url('../../assets/corbac/normal_left.png');
}
.corbac.normal.right,
#bd_editor #scene .holder.bird-holder.corbac.normal.right {
  background-image: url('../../assets/corbac/normal_right.png');
}
.corbac.shocked,
#bd_editor #scene .holder.bird-holder.corbac.shocked {
  background-image: url('../../assets/corbac/shocked.png');
}
.corbac.shocked.speaking,
#bd_editor #scene .holder.bird-holder.corbac.shocked.speaking {
  background-image: url('../../assets/corbac/shocked_speaking.png');
}
.corbac.speaking,
#bd_editor #scene .holder.bird-holder.corbac.speaking {
  background-image: url('../../assets/corbac/speaking.png');
}
.corbac.speaking.left,
#bd_editor #scene .holder.bird-holder.corbac.speaking.left {
  background-image: url('../../assets/corbac/speaking_left.png');
}
.corbac.speaking.right,
#bd_editor #scene .holder.bird-holder.corbac.speaking.right {
  background-image: url('../../assets/corbac/speaking_right.png');
}
.thumb.bluesky {
  background: url('../../assets/icons/blue-sky_icon.png') no-repeat 50% 50%;
}
.thumb.noel_day {
  background: url('../../assets/icons/noel_day.png') no-repeat 50% 50%;
}
.thumb.noel_night {
  background: url('../../assets/icons/noel_night.png') no-repeat 50% 50%;
}
.thumb.halloween_1 {
  background: url('../../assets/icons/halloween_lune.png') no-repeat 50% 50%;
}
.thumb.halloween_2 {
  background: url('../../assets/icons/halloween_cimetiere.png') no-repeat 50% 50%;
}
.thumb.halloween_3 {
  background: url('../../assets/icons/halloween_eclair.png') no-repeat 50% 50%;
}
.thumb.summer {
  background: url('../../assets/icons/summer_icon.png') no-repeat 50% 50%;
}
.thumb.sq_bluesky {
  background: url('../../assets/icons/blue-sky_icon.png') no-repeat 50% 50%;
}

@font-face {
    font-family: 'gilles_comic_fontregular';
    src: url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.eot');
    src: url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.woff2') format('woff2'),
    url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.woff') format('woff'),
    url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.ttf') format('truetype'),
    url('../../assets/fonts/gilles_handwriting/gilles_handwriting-webfont.svg#gilles_comic_fontregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.Editor {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SceneWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-x: auto;
}

@media only screen and (max-width: 824px) {
    .SceneWrapper.standard {
        align-items: start;
    }
}
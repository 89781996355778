.CommentForm {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.CommentForm input {
  flex-grow: 1;
  padding: 0.4rem;
  border: 1px solid #2d80dd;
}
.CommentForm button {
  background-color: white;
  border: 1px solid #2d80dd;
  color: #2d80dd;
}
